import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { DatumService } from 'src/app/services/datum/datum.service';

@Component({
  selector: 'batp-gold',
  templateUrl: './gold.component.html',
  styleUrls: ['./gold.component.css']
})
export class GoldComponent implements OnInit {
  public QRCode: string;
  userDetails: any;
  token;
  public type: string;
  public level: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GoldComponent>,
  private _url: ApiUrlService,
    private _req: ApirequestService, private _datum: DatumService) { 
      this.type = data.type;
    this.level = data.level;
    }

  ngOnInit() {
    const userDetails = this._datum.getUserDetails();

    this.qrCode(userDetails.username);
    
  }
  
  qrCode(name: string): void {
    const data = {
      level: this.level,
      username: name
    };
    this._req.fetchApiData(this._url.getQRcodeUrl, data, false).subscribe((response: any) => {
      if (response) {
        this.QRCode = 'data:image/png;base64,' + response.data;
        // this.getAddWebHooks();
      }
    });
  }

  // getAddWebHooks() {
  //   const data = {
  //     url: 'https://apollo.business-software.in/api/updateKmn'
  //   };
  //   this._req.fetchPost(this._url.webHooksAddUrl, data, this.token).subscribe((res: any) => {
  //   });
  // }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { FsUploadService } from '../../../../services/fs-upload/fs-upload.service';
import { financialDocument,financialDoc } from '../../../../shared/data/financial-docs';


@Component({
  selector: 'batp-wiz-financial-document',
  templateUrl: './wiz-financial-document.component.html',
  styleUrls: ['./wiz-financial-document.component.css']
})
export class WizFinancialDocumentComponent implements OnInit {

  constructor(private _fb:FormBuilder,
              private _fs:FsUploadService) { }
  @Input() goTo:any = '';
  @Output() mgmtStepper:any = new EventEmitter();
  @Output() companyFinDocs = new EventEmitter();
  @Input() companyLogo:any = '';

  documentInfo:any = [];
  companyCommercialDetail:any = {};
  comp_detailsDetail:any = {};
  fdDetail:any = {};
  prospectusDetail:any = {};
  bussPlanDetail:any = {};
  bussvalDetail:any = {};
  onePagerExeDetail:any = {};


  fileDocDetails:any = {};
  @Input() finDocs:any = '';

  companyDocForm:FormGroup;
  financialDocument: financialDoc[] = financialDocument;

  companyMgmtFormInit() {
    this.companyDocForm = this._fb.group({
     
    'commercial_register': this._fb.group(
      {
        document_name: [''],
        document_url: ['']
      }
    ),
    'comp_details': this._fb.group(
      {
        document_name: [''],
        document_url: ['']
      }
    ),
    'financial_document': this._fb.group(
      {
        document_name: [''],
        document_url: ['']
      }
    ),
    'prospectus': this._fb.group(
      {
        document_name: [''],
        document_url: ['']
      }
    ),
    'business_plan': this._fb.group(
      {
        document_name: [''],
        document_url: ['']
      }
    ),
    'business_valuation': this._fb.group(
      {
        document_name: [''],
        document_url: ['']
      }
    ),
    'one_pager_summary': this._fb.group(
      {
        document_name: [''],
        document_url: ['']
      }
    ),
    'other_financial_document': this._fb.array([])
    });
  }

  get otherFinDoc() { return <FormArray>this.companyDocForm.get('other_financial_document'); }


  addFinDocs(): void {
    let docs = this.companyDocForm.get('other_financial_document') as FormArray;
    docs.push(this.createDocFields());
  }

  delFinDocs(i) {
    let docs = this.companyDocForm.get('other_financial_document') as FormArray;
    docs.removeAt(i);
  }
  createDocFields(req:boolean = false): FormGroup {
    return this._fb.group({
      document_name: [''],
      document_url: [''],
      file_name: [''],
      file_icon: ['']
    });
  }

  goToPrevious() {
    // this.setDocumentDetails();
    let data = { documentInfo: this.documentInfo };
    let prevExpData:any = {
      formData: data,
      goTo: 3
    };
    // this.companyFinDocs.emit(exportData);
    this.mgmtStepper.emit(prevExpData);
  }

  // uploadFiles(type,i = null) {
  //   let fileAccepted = [".pdf",".doc",".docx",".docm","image/*"];
  //   let maxSize = 10485760;
  //   this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
  //     if(res.filesUploaded.length > 0) {
  //       if(type == 'other_financial_document') {
  //         this.otherFinDocUploader(type,res,i);
  //         return;
  //       }
  //       this.fileUploadHandler(type,res)
  //     }
  //   });
  // }
  fileDocTypeInc: number = 1;
  uploadFiles() {
    let fileAccepted = [".pdf",".doc",".docx",".docm","image/*"];
    let maxSize = 10485760;
    this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
      if(res.filesUploaded.length > 0) {
        this.fileUploadHandle(res)
      }
    });
  }
  fileUploadHandle(res) {
    let fileUploaded = res.filesUploaded;
    if(fileUploaded.length > 0 ) {
      this.documentInfo.push({
        docTitle: fileUploaded[0].filename.split('.').slice(0, -1).join('.'),
        docName: fileUploaded[0].url,
        docType: this.fileDocTypeInc,
      })
      this.fileDocTypeInc++;
    }
    console.log(this.documentInfo);
  }
  removeUploadFiles(file) {
    let ind = this.documentInfo.findIndex(el => el.docType == file.docType)
    let removed = this.documentInfo.splice(ind,1);
    console.log(ind)
    console.log(removed)
    console.log(this.documentInfo)
  }





  otherFinDocUploader(type,res,i) {
    let filesFailed = res.filesFailed;
    let fileUploaded = res.filesUploaded;
    if(fileUploaded.length > 0 ) {
      let controlContainter = this.companyDocForm.get(type)['controls'][i]['controls'];
      controlContainter['document_url'].setValue(fileUploaded[0].url);
      this.getOtherDocDetails(type,fileUploaded[0],i);
      // this.setOtherDocDetails(type,fileUploaded[0]);
    } 
  }
  getOtherDocDetails(type,file,i) {
    let controlContainter = this.companyDocForm.get(type)['controls'][i]['controls'];
    controlContainter['file_name'].setValue(file.filename);
    controlContainter['file_icon'].setValue(this.getDocumentIcon(file));
  }
  setOtherDocDetails(type,file) {

  }
  fileUploadHandler(type,res) {
    let filesFailed = res.filesFailed;
    let fileUploaded = res.filesUploaded;
    if(fileUploaded.length > 0 ) {
      <FormArray>this.companyDocForm.get(type)['controls']['document_url'].setValue(fileUploaded[0].url);
      this.getDocumentDetails(type,fileUploaded[0]);
      // this.setDocumentDetails(fileUploaded[0],type);
    }
  }
  getDocumentDetails(type,file?) {
    this.fileDocDetails[type] = {};
    this.fileDocDetails[type]['icon'] = this.getDocumentIcon(file);
    this.fileDocDetails[type]['filename'] = file.filename;
    let formVal = this.companyDocForm.value;
    formVal[type].document_name = type;
    // switch (type) {
    //   case 'commercial_register':
    //     this.companyCommercialDetail.icon = this.getDocumentIcon(file);
    //     this.companyCommercialDetail.filename = file.filename;
    //     break;
    //   case 'comp_details':
    //     this.comp_detailsDetail.icon = this.getDocumentIcon(file);
    //     this.comp_detailsDetail.filename = file.filename;
    //     break;
    //   case 'financial_document':
    //     this.fdDetail.icon = this.getDocumentIcon(file);
    //     this.fdDetail.filename = file.filename;
    //     break;
    //   default:
    //     break;
    // }
  }
  setDocumentDetails() {
    // let docType = this.getDocType(type);
    // this.documentInfo.push({
    //   docTitle: 'document'+docType,
    //   docName: res.url,
    //   docType: docType,
    // })
    this.documentInfo = [];
    this.documentInfo.push(this.companyLogo[0]);
    let formVal = this.companyDocForm.value
    let commercial_register = this.companyDocForm.value.commercial_register;
    let comp_details = this.companyDocForm.value.comp_details;
    let financial_document = this.companyDocForm.value.financial_document;
    Object.keys(formVal).forEach((key,index) => {
      if(key == 'other_financial_document') {
        formVal[key].forEach(ele => {
          if(ele.document_name != '') {
            this.documentInfo.push({
              docTitle: ele.document_name,
              docName: ele.document_url,
              docType: 9,
            })
          }
        });
      } else {
        if(formVal[key].document_name != '') {
          this.documentInfo.push({
            docTitle: formVal[key].document_name,
            docName: formVal[key].document_url,
            docType: this.getDocType(key),
          });
        }
      }
    });
  }
  getDocNameByID(id) {
    let docName;
    this.financialDocument.forEach(ele => {
      if(ele.id == id) docName = ele.name; 
    });
    return docName;
  }
  
  getDocumentIcon(file?) {
    let icon = 'fal fa-file-alt';
    if(typeof file != 'undefined') {
      let ext = file.filename.split('.').pop();
      switch (ext) {
        case 'pdf':
          icon = 'fal fa-file-pdf';
          break;
        case 'jpg':
        case 'jpeg':
        case 'png':
          icon = 'fal fa-file-image';
          break;
        default:
          icon = 'fal fa-file-alt';
          break;
      }
    }
    return icon;
  }

  getDocType(type) {
    let doctype;
    switch (type) {
      case 'commercial_register':
        doctype = 2;
        break;
      case 'comp_details':
        doctype = 3;
        break;
      case 'financial_document':
        doctype = 4;
        break;
      case 'prospectus':
        doctype = 5;
      break;
      case 'business_plan':
        doctype = 6;
      break;
      case 'business_valuation':
        doctype = 7;
      break;
      case 'one_pager_summary':
        doctype = 8;
      break;
    }
    return doctype
  }

  removeDocument(type,docType) {
    this.companyDocForm.controls[type].setValue('');
    this.removeDocumentInfo(docType);
  }
  removeDocumentInfo(docType) {
    for(var i = 0; i < this.documentInfo.length; i++) {
      if(this.documentInfo[i].docTitle == docType) {
        this.documentInfo.splice(i, 1);
        break;
      }
    }
  }

  companyFinDocsSubmit() {
    let data = {
      documentInfo: this.documentInfo
    }
    let exportData:any = {
      formData: data,
      goTo: 6
    };
    this.companyFinDocs.emit(exportData);
  }

  ngOnInit() {
    this.companyMgmtFormInit();
  }

  setDocumentInfo() {
    let docInfo = this.finDocs.documentInfo
    docInfo.forEach(el => {
      this.documentInfo.push(el);
    });
  }
  ngOnChanges() {
    if(this.companyLogo != '') {
      this.documentInfo.push(this.companyLogo[0]);
    }
    if(this.finDocs!='') {
      this.setDocumentInfo();
    }
  }
}

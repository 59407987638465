import { Component, OnInit } from '@angular/core';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { LogoutService } from '../../../services/logout/logout.service';
import { UtilityService } from '../../../services/utilities/utility.service';
import { hyb_udata } from '../../../shared/data/constant-data';

@Component({
  selector: 'batp-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {

  constructor(private _req:ApirequestService,
    private _url:ApiUrlService,
    private _ls:LoginService,
    private _lo:LogoutService,
    private _route:Router,
    private _utils:UtilityService,
    private _ar:ActivatedRoute) { }
  errMessage:string = '';
  activationStatus:boolean = true;
  mailActivation(data) {
    this._req.fetchApiData(this._url.tempEmailUpdateUrl,data).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          let userData = this._utils.getFromStorage(hyb_udata);
          if(userData) {
            this._lo.logOut('','Please check your inbox to complete the email change.');
          } else {
            if(resSucc.status == 'active') this._route.navigate(['/']);
          }
          //this._ls.setLoginAction(resSucc);
        }
        if(resErr != '') {
          this.activationStatus = false;
          this.errMessage = resErr['Error Description'];
        }
      },
      error => {

      },
      () => {

      }
    )
  }

  ngOnInit() {
    this._ar.queryParams.subscribe(
      data => {
        if( data.idUser && data.activationKey) {
          this.mailActivation(data);
        }
      }
    )
  }

}

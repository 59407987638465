import { Component, OnInit } from '@angular/core';
import { AccountDetailsService } from '../../../../services/accountDetails/account-details.service';
import { UtilityService } from '../../../../services/utilities/utility.service';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { DatumService } from '../../../../services/datum/datum.service';
import { MatDialog, Sort } from '@angular/material';
import { ModalBoxComponent } from '../../../widgets/modal-box/modal-box.component';
import { InitialDepositModalComponent } from 'src/app/components/widgets/initial-deposit-modal/initial-deposit-modal.component';
import { UpdateProfileModalComponent } from 'src/app/components/widgets/update-profile-modal/update-profile-modal.component';
import { Store, select } from '@ngrx/store';
import * as rootState from '../../../../app.reducer';

interface accountDetails {
  primary: any[],
  secondary: any[],
  transactions: any[]
}
declare var $: any;
@Component({
  selector: 'batp-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class BalanceComponent implements OnInit {

  public currentData;
  constructor(private _accDetail: AccountDetailsService,
    private _datum: DatumService,
    private store: Store<rootState.State>,
    private _dialog: MatDialog,
    private _utils: UtilityService,
    ) {
  }

  accountDetails: accountDetails = {
    primary: [], secondary: [], transactions: []
  };
  tranxLoaded: boolean = false;
  showLoader: boolean = true;
  tranxLoader: boolean = true;
  userDetails: any;


  tranxDetailsSub: any;


  ngOnInit() {
    this.getUserTranxDetails();
    this.getUserDetails();
    // const orderBookWallet = this.socketService.orderBookWallet;
    // this.socketService.orderBookWallet.subscribe(doc => {
    //   if (doc) {
    //       console.log('orderBookWallet', doc);
    //     this.currentData = doc.data;
    //     console.log(doc);
    //   }
    //   //  this.currentDoc = doc.id
    // });
  }


  getUserTranxDetails() {
    this._accDetail.getTranxDetails();
    this.tranxDetailsSub = this._accDetail.tranxDetails
      .subscribe(data => {
        if (data != 'error') {
          if (data.length > 0) {
            this.tranxLoaded = true;
            this.tranxLoader = false;
            this.accountDetails.transactions = data;
          }
        } else if (data == 'error') {
          this.tranxLoaded = true;
          this.tranxLoader = false;
        }
      });
  }

  


  getUserDetails() {
    // this.userDetails = this._datum.getUserDetails();
    this.store.pipe(select('authData')).subscribe(
      (data: any) => {
        this.userDetails = data.userDetail;
      }
    );
  }

  alertModalBox() {
    let message = 'Please Complete Your Silver Verification Level';
    const dialogRef = this._dialog.open(ModalBoxComponent, {
      width: '450px',
      data: {
        message: message,
        buttonText: 'OK'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  sortTrxData(sort: Sort) {
    const data = this.accountDetails.transactions.slice();
    if (!sort.active || sort.direction === '') {
      this.accountDetails.transactions = data;
      return;
    }
    this.accountDetails.transactions = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'txnDate': return compare(a.txnDate, b.txnDate, isAsc);
        case 'stockName': return compare(a.stockName, b.stockName, isAsc);
        case 'txnType': return compare(a.txnType, b.txnType, isAsc);
        case 'amount': return compare(a.amount, b.amount, isAsc);
        case 'balance': return compare(a.balance, b.balance, isAsc);
        default: return 0;
      }
    });
  }


  openDepositModal() {
    const dialogRef = this._dialog.open(InitialDepositModalComponent, {
      width: '700px',
      data: {
        showPayment: true,
        userDetail: this.userDetails
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this._route.navigate(['/']);
      }
    });
  }
  updateBronzeLvl() {
    const dialogRef = this._dialog.open(UpdateProfileModalComponent, {
      width: '650px',
      data: this.userDetails
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }



  ngOnDestroy() {
    if (typeof this.tranxDetailsSub != 'undefined') this.tranxDetailsSub.unsubscribe();
  }

}

function compare(a: any, b: any, isAsc: boolean) {
  let item1 = a; let item2 = b;
  if (!isNaN(a)) item1 = parseFloat(a);
  if (!isNaN(b)) item2 = parseFloat(b);
  return (item1 < item2 ? -1 : 1) * (isAsc ? 1 : -1);
}

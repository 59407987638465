import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AmChartsModule } from '@amcharts/amcharts3-angular';


import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/header/header.component';
import { HomeComponent } from './components/pages/home/home.component';
// Routing Module
import { BatpRoutingModule } from './modules/routing/app.routing.module';
import { HomeTickerComponent } from './components/widgets/home-ticker/home-ticker.component';
import { HomeCompanyListComponent } from './components/pages/home/home-company-list/home-company-list.component';
import { HomeUserLoginComponent } from './components/pages/home/home-user-login/home-user-login.component';
import { RegisterSectionComponent } from './components/common/register-section/register-section.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { RegistrationComponent } from './components/pages/registration/registration.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import { ApiUrlService } from './services/api-url/api-url.service';
import { ApiserviceService } from './services/apiservice/apiservice.service';
import { StorageService } from './services/localstorage/storage.service';
import { ApirequestService } from './services/apirequest/apirequest.service';
import { BatpConfig } from './config/batp.config';
import { HttpClientModule } from '@angular/common/http';
import { UtilityService } from './services/utilities/utility.service';
import { TimerService } from './services/utilities/timer/timer.service';
import { LoginComponent } from './components/pages/login/login.component';
import { LoginService } from './services/login/login.service';
import { SocialLoginComponent } from './components/common/social-login/social-login.component';
import { UserRegisterComponent } from './components/pages/user-register/user-register.component';
import { IssuerRegisterComponent } from './components/pages/issuer-register/issuer-register.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { batpReducers } from './app.reducer';
import { MessageBoxComponent } from './components/widgets/message-box/message-box.component';
import { RegisterService } from './services/register/register.service';

/* Captcha  Module */
import { NgxCaptchaModule } from 'ngx-captcha';
import { IssuerRegisterWizardComponent } from './components/pages/issuer-register-wizard/issuer-register-wizard.component';
import { WizCompanyInformationComponent } from './components/pages/issuer-register-wizard/wiz-company-information/wiz-company-information.component';
import { WizCompanyDescriptionComponent } from './components/pages/issuer-register-wizard/wiz-company-description/wiz-company-description.component';
import { WizManagementInformationComponent } from './components/pages/issuer-register-wizard/wiz-management-information/wiz-management-information.component';
import { WizFinancialDocumentComponent } from './components/pages/issuer-register-wizard/wiz-financial-document/wiz-financial-document.component';
import { WizRegistratinSummaryComponent } from './components/pages/issuer-register-wizard/wiz-registratin-summary/wiz-registratin-summary.component';
import { WizRegistrationCompleteComponent } from './components/pages/issuer-register-wizard/wiz-registration-complete/wiz-registration-complete.component';
import { FsUploadService } from './services/fs-upload/fs-upload.service';
import { CryptoService } from './services/crypto/crypto.service';
import { LogoutService } from './services/logout/logout.service';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { SecurityComponent } from './components/pages/profile/security/security.component';
import { SettingsComponent } from './components/pages/profile/settings/settings.component';
import { VerificationComponent } from './components/pages/profile/verification/verification.component';
import { BronzeComponent } from './components/pages/profile/verification/bronze/bronze.component';
import { SilverComponent } from './components/pages/profile/verification/silver/silver.component';
import { GoldComponent } from './components/pages/profile/verification/gold/gold.component';
import { WalletComponent } from './components/pages/wallet/wallet.component';
import { BalanceComponent } from './components/pages/wallet/balance/balance.component';
import { HistoryComponent } from './components/pages/wallet/history/history.component';
import { OrdersComponent } from './components/pages/wallet/orders/orders.component';
import { DatumService } from './services/datum/datum.service';
import { GetnamePipe } from './pipes/utils/getname.pipe';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatCheckboxModule, MatRadioModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { HighchartsChartModule } from 'highcharts-angular';


import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { PaginationComponent } from './components/widgets/pagination/pagination.component';
import { DialogBoxComponent } from './components/material/dialog-box/dialog-box.component';
import { ModalBoxComponent } from './components/widgets/modal-box/modal-box.component';
import { DepositBoxComponent } from './components/pages/wallet/balance/deposit-box/deposit-box.component';
import { WithdrawalBoxComponent } from './components/pages/wallet/balance/withdrawal-box/withdrawal-box.component';
import { PageBannerComponent } from './components/common/page-banner/page-banner.component';
import { AboutUsComponent } from './components/content-page/about-us/about-us.component';
import { LearningCenterComponent } from './components/content-page/learning-center/learning-center.component';
import { PressCenterComponent } from './components/content-page/press-center/press-center.component';
import { ContactFormComponent } from './components/common/contact-form/contact-form.component';
import { PressImageComponent } from './components/content-page/press-center/press-image/press-image.component';
import { PressContentComponent } from './components/content-page/press-center/press-content/press-content.component';
import { DLogoComponent } from './components/content-page/press-center/d-logo/d-logo.component';
import { ContactUsComponent } from './components/content-page/contact-us/contact-us.component';
import { LegalComponent } from './components/content-page/legal/legal.component';
import { LegalDetailContentComponent } from './components/content-page/legal/legal-detail-content/legal-detail-content.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { Error404Component } from './components/error/error404/error404.component';
import { AuthenticationGuard } from './gaurds/auth/auth.guard';
import { IboComponent } from './components/pages/ibo/ibo.component';
import { AuthenticationOffGuard } from './gaurds/auth/auth-off-guard';
import { IboCompanyComponent } from './components/pages/ibo/ibo-company/ibo-company.component';
import { AccountActivationComponent } from './components/pages/account-activation/account-activation.component';
import { IssuerRegisterGuard } from './gaurds/issuerRegister/issuerRegister.guard';
import { ChangeEmailComponent } from './components/pages/change-email/change-email.component';
import { VideoDialogComponent } from './components/widgets/video-dialog/video-dialog.component';
import { AuthServiceConfig, SocialLoginModule } from 'angular-6-social-login-v2';
import { provideSMLConfig } from './config/sml.config';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { UpdateDocumentComponent } from './components/common/update-document/update-document.component';
import { IssuerRegisterOffGuard } from './gaurds/issuerRegister/issuerRegister.off.guard';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { SafePipePipe } from './pipes/utils/safe-pipe.pipe';
import { HomeVideoComponent } from './components/pages/home/home-video/home-video.component';
import { NumberDecimalFixedPipe } from './pipes/utils/number-decimal-fixed.pipe';
import { TwoFactorService } from './services/twoFactor/two-factor.service';
import { PressReleaseDetailComponent } from './components/content-page/press-center/press-release-detail/press-release-detail.component';
import { TransferBoxComponent } from './components/pages/wallet/balance/transfer-box/transfer-box.component';
import { CompanyInformationComponent } from './components/issuer-backend/company-profile/company-information/company-information.component';
import { IssuerProfileComponent } from './components/issuer-backend/issuer-profile.component';
import { CompanyDescriptionComponent } from './components/issuer-backend/company-profile/company-description/company-description.component';
import { RounddecimalsPipe } from './pipes/utils/rounddecimals.pipe';
import { KeysPipe } from './pipes/utils/keyvalue.pipe';
import { ManagementInformationComponent } from './components/issuer-backend/company-profile/management-information/management-information.component';
import { FinancialDocumentComponent } from './components/issuer-backend/company-profile/financial-document/financial-document.component';
import { GetfilehandlePipe } from './pipes/utils/getfilehandle.pipe';
import { ConfirmBoxComponent } from './components/widgets/confirm-box/confirm-box.component';
import { MarketsComponent } from './components/pages/markets/markets.component';
import { MarketInfoNoviceComponent } from './components/pages/markets/market-info-novice/market-info-novice.component';
import { MarketInfoMobileComponent } from './components/pages/markets/market-info-mobile/market-info-mobile.component';
import { MarketInfoComponent } from './components/pages/markets/market-info/market-info.component';
import { DoubleWalletComponent } from './components/pages/wallet/balance/double-wallet/double-wallet.component';
import { TradingChartsComponent } from './components/widgets/trading-charts/trading-charts.component';
import { MarketDepthChartComponent } from './components/widgets/market-depth-chart/market-depth-chart.component';
import { MarketGuard } from './gaurds/marketGaurd/market.guard';
import { MarketDetailsComponent } from './components/pages/markets/market-details/market-details.component';
import { SilverPlusComponent } from './components/pages/profile/verification/silver-plus/silver-plus.component';
import { IbFooterComponent } from './components/issuer-backend/ib-footer/ib-footer.component';
import { FooterstripeComponent } from './components/common/footer/footerstripe/footerstripe.component';
import { DocumentCenterComponent } from './components/content-page/document-center/document-center.component';
import { NewsTickerComponent } from './components/widgets/news-ticker/news-ticker.component';
import { RegisterPaymentMethodComponent } from './components/common/register-payment-method/register-payment-method.component';
import { BankTransferComponent } from './components/common/register-payment-method/bank-transfer/bank-transfer.component';
import { DimCurrencyComponent } from './components/common/register-payment-method/dim-currency/dim-currency.component';
import { InitialDepositModalComponent } from './components/widgets/initial-deposit-modal/initial-deposit-modal.component';
import { UpdateProfileModalComponent } from './components/widgets/update-profile-modal/update-profile-modal.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { IbinService } from './services/ibin/ibin.service';
import { NumberDirective } from './directives/number.directive';
import { HighChartsComponent } from './components/widgets/high-charts-depth/high-charts.component';
import { MarketTickerComponent } from './components/widgets/ticker/market-ticker.component';
import { HighAreaChartsComponent } from './components/widgets/high-charts-depth/high-area-charts.component';
import { JsonpModule } from '@angular/http';
import { WizStockInformationComponent } from './components/pages/issuer-register-wizard/wiz-stock-information/wiz-stock-information.component';
import { WizIboComponent } from './components/pages/issuer-register-wizard/wiz-ibo/wiz-ibo.component';
import { UserRegisterStepsComponent } from './components/pages/user-register-steps/user-register-steps.component';
import { ConfirmLoginComponent } from './components/widgets/confirm-login/confirm-login.component';
import { BuyOrderComponent } from './components/widgets/buy-order/buy-order.component';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './components/pages/profile/account/account.component';
import { ProfileProComponent } from './components/pages/profile/profile-pro/profile-pro.component';
import { IboInformationComponent } from './components/issuer-backend/ibo-information/ibo-information.component';
import { LimitBuyComponent } from './components/widgets/limit-buy/limit-buy.component';
import { LimitSellComponent } from './components/widgets/limit-sell/limit-sell.component';
import { ConfirmOrderLoginComponent } from './components/widgets/confirm-order-login/confirm-order-login.component';
import { IboCompanyInformationComponent } from './components/issuer-backend/company-profile/ibo-company-information/ibo-company-information.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RedeemBoxComponent } from './components/pages/wallet/balance/redeem-box/redeem-box.component';
import { GetInTouchComponent } from './components/pages/get-in-touch/get-in-touch.component';
import { ListingComponent } from './components/content-page/listing/listing.component';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// import { SocketService } from './services/websocket/socket.service';
// import { DocumentListComponent } from './components/document-list/document-list.component';
// import { DocumentComponent } from './components/document/document.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
// const config: SocketIoConfig = { url: 'https://hybsewebsocket.business-software.in', options: {} };


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    HomeTickerComponent,
    HomeCompanyListComponent,
    HomeUserLoginComponent,
    RegisterSectionComponent,
    FooterComponent,
    RegistrationComponent,
    LoaderComponent,
    LoginComponent,
    SocialLoginComponent,
    UserRegisterComponent,
    IssuerRegisterComponent,
    MessageBoxComponent,
    IssuerRegisterWizardComponent,
    WizCompanyInformationComponent,
    WizCompanyDescriptionComponent,
    WizManagementInformationComponent,
    WizFinancialDocumentComponent,
    WizRegistratinSummaryComponent,
    WizRegistrationCompleteComponent,
    ProfileComponent,
    SecurityComponent,
    SettingsComponent,
    VerificationComponent,
    BronzeComponent,
    SilverComponent,
    GoldComponent,
    WalletComponent,
    BalanceComponent,
    HistoryComponent,
    OrdersComponent,
    GetnamePipe,
    PaginationComponent,
    DialogBoxComponent,
    ModalBoxComponent,
    DepositBoxComponent,
    WithdrawalBoxComponent,
    PageBannerComponent,
    AboutUsComponent,
    LearningCenterComponent,
    PressCenterComponent,
    ContactFormComponent,
    PressImageComponent,
    PressContentComponent,
    DLogoComponent,
    ContactUsComponent,
    LegalComponent,
    LegalDetailContentComponent,
    FaqComponent,
    Error404Component,
    IboComponent,
    IboCompanyComponent,
    AccountActivationComponent,
    ChangeEmailComponent,
    VideoDialogComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UpdateDocumentComponent,
    ComingSoonComponent,
    SafePipePipe,
    HomeVideoComponent,
    NumberDecimalFixedPipe,
    PressReleaseDetailComponent,
    TransferBoxComponent,
    CompanyInformationComponent,
    IssuerProfileComponent,
    CompanyDescriptionComponent,
    RounddecimalsPipe,
    ManagementInformationComponent,
    FinancialDocumentComponent,
    GetfilehandlePipe,
    ConfirmBoxComponent,
    MarketsComponent,
    MarketInfoComponent,
    MarketInfoNoviceComponent,
    MarketInfoMobileComponent,
    DoubleWalletComponent,
    TradingChartsComponent,
    MarketDepthChartComponent,
    MarketDetailsComponent,
    SilverPlusComponent,
    IbFooterComponent,
    FooterstripeComponent,
    DocumentCenterComponent,
    NewsTickerComponent,
    RegisterPaymentMethodComponent,
    BankTransferComponent,
    DimCurrencyComponent,
    InitialDepositModalComponent,
    UpdateProfileModalComponent,
    NumberDirective,
    HighChartsComponent,
    MarketTickerComponent,
    HighAreaChartsComponent,
    WizStockInformationComponent,
    WizIboComponent,
    UserRegisterStepsComponent,
    ConfirmLoginComponent,
    BuyOrderComponent,
    KeysPipe,
    AccountComponent,
    ProfileProComponent,
    IboInformationComponent,
    LimitBuyComponent,
    LimitSellComponent,
    ConfirmOrderLoginComponent,
    IboCompanyInformationComponent,
    RedeemBoxComponent,
    GetInTouchComponent,
    ListingComponent,
    // DocumentComponent,
    // DocumentListComponent

  ],

  entryComponents: [
    ModalBoxComponent,
    InitialDepositModalComponent,
    UpdateProfileModalComponent,
    VideoDialogComponent,
    ConfirmBoxComponent,
    ConfirmLoginComponent,
    BuyOrderComponent,
    BronzeComponent,
    GoldComponent,
    SilverComponent,
    SilverPlusComponent,
    LimitBuyComponent,
    LimitSellComponent,
    ConfirmOrderLoginComponent],

  imports: [
    HttpClientModule,
    BatpRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxQRCodeModule,
    NoopAnimationsModule,
    AmChartsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatExpansionModule,
    MatCardModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatBottomSheetModule,
    FormsModule,
    NgxCaptchaModule,
    SocialLoginModule,
    StoreModule.forRoot(batpReducers),
    ReactiveFormsModule,
    BrowserModule,
    PerfectScrollbarModule,
    HighchartsChartModule,
    JsonpModule,
    TooltipModule.forRoot(),
    // SocketIoModule.forRoot(config)
  ],
  providers: [
    BatpConfig,
    {
      provide: AuthServiceConfig,
      useFactory: provideSMLConfig
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ApiUrlService,
    ApirequestService,
    AuthenticationGuard,
    AuthenticationOffGuard,
    IssuerRegisterGuard,
    IssuerRegisterOffGuard,
    MarketGuard,
    ApiserviceService,
    StorageService,
    UtilityService,
    TimerService,
    LoginService,
    TwoFactorService,
    RegisterService,
    FsUploadService,
    CryptoService,
    LogoutService,
    IbinService,
    DatumService,
    // SocketService
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utilities/utility.service';

@Component({
  selector: 'batp-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {

  constructor(private _utils:UtilityService) { }

  isMobile:boolean = false;

  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { DatumService } from '../../../../../services/datum/datum.service';
import { SnackbarService } from '../../../../../services/snackbar/snackbar.service';


@Component({
  selector: 'batp-deposit-box',
  templateUrl: './deposit-box.component.html',
  styleUrls: ['./deposit-box.component.css']
})
export class DepositBoxComponent implements OnInit {

  constructor(private _datum: DatumService,private _sb:SnackbarService) { }
  @Input() assetData:any = [];
  userDetails:any;
  walletAddress:string = '';
  show_dialog : boolean = false;
  getUserDetails() {
    this.userDetails = this._datum.getUserDetails();
    this.walletAddress = this.userDetails.internalAddress;
  }
  copyAddress(input) {
    input.select();
    document.execCommand("copy");
    this._sb.openSnackBar('Wallet Address Copied to Clipboard',2000,'batp_success_snackbar','right','top');
  }
  toggle() {
    this.show_dialog = !this.show_dialog;
  }

  ngOnInit() {
    this.getUserDetails();
  }

  ngOnChanges() {
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'batp-home-company-list',
  templateUrl: './home-company-list.component.html',
  styleUrls: ['./home-company-list.component.css']
})
export class HomeCompanyListComponent implements OnInit {

  constructor() { }
  @Input() iboLists:any = [];
  @Input() showLoader:boolean = true;


  ngOnInit() {

  }
  ngOnChanges() {

  }

}

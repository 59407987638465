import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '../../app.component';
import { HomeComponent } from '../../components/pages/home/home.component';
import { RegistrationComponent } from '../../components/pages/registration/registration.component';
import { LoginComponent } from '../../components/pages/login/login.component';
import { UserRegisterComponent } from '../../components/pages/user-register/user-register.component';
import { IssuerRegisterComponent } from '../../components/pages/issuer-register/issuer-register.component';
import { IssuerRegisterWizardComponent } from '../../components/pages/issuer-register-wizard/issuer-register-wizard.component';
import { ProfileComponent } from '../../components/pages/profile/profile.component';
import { SecurityComponent } from '../../components/pages/profile/security/security.component';
import { SettingsComponent } from '../../components/pages/profile/settings/settings.component';
import { VerificationComponent } from '../../components/pages/profile/verification/verification.component';
import { SilverComponent } from '../../components/pages/profile/verification/silver/silver.component';
import { GoldComponent } from '../../components/pages/profile/verification/gold/gold.component';
import { WalletComponent } from '../../components/pages/wallet/wallet.component';
import { BalanceComponent } from '../../components/pages/wallet/balance/balance.component';
import { HistoryComponent } from '../../components/pages/wallet/history/history.component';
import { OrdersComponent } from '../../components/pages/wallet/orders/orders.component';
import { AboutUsComponent } from '../../components/content-page/about-us/about-us.component';
import { LearningCenterComponent } from '../../components/content-page/learning-center/learning-center.component';
import { PressCenterComponent } from '../../components/content-page/press-center/press-center.component';
import { PressImageComponent } from '../../components/content-page/press-center/press-image/press-image.component';
import { DLogoComponent } from '../../components/content-page/press-center/d-logo/d-logo.component';
import { PressContentComponent } from '../../components/content-page/press-center/press-content/press-content.component';
import { ContactUsComponent } from '../../components/content-page/contact-us/contact-us.component';
import { LegalComponent } from '../../components/content-page/legal/legal.component';
import { LegalDetailContentComponent } from '../../components/content-page/legal/legal-detail-content/legal-detail-content.component';
import { Error404Component } from '../../components/error/error404/error404.component';
import { AuthenticationGuard } from '../../gaurds/auth/auth.guard';
import { AuthenticationOffGuard } from '../../gaurds/auth/auth-off-guard';
import { IboComponent } from '../../components/pages/ibo/ibo.component';
import { IboCompanyComponent } from '../../components/pages/ibo/ibo-company/ibo-company.component';
import { AccountActivationComponent } from '../../components/pages/account-activation/account-activation.component';
import { IssuerRegisterGuard } from '../../gaurds/issuerRegister/issuerRegister.guard';
import { ChangeEmailComponent } from '../../components/pages/change-email/change-email.component';
import { ForgotPasswordComponent } from '../../components/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../../components/pages/reset-password/reset-password.component';
import { IssuerRegisterOffGuard } from '../../gaurds/issuerRegister/issuerRegister.off.guard';
import { ComingSoonComponent } from '../../components/pages/coming-soon/coming-soon.component';
import { PressReleaseDetailComponent } from 'src/app/components/content-page/press-center/press-release-detail/press-release-detail.component';
import { CompanyInformationComponent } from 'src/app/components/issuer-backend/company-profile/company-information/company-information.component';
import { IssuerProfileComponent } from 'src/app/components/issuer-backend/issuer-profile.component';
import { CompanyDescriptionComponent } from 'src/app/components/issuer-backend/company-profile/company-description/company-description.component';
import { ManagementInformationComponent } from 'src/app/components/issuer-backend/company-profile/management-information/management-information.component';
import { FinancialDocumentComponent } from 'src/app/components/issuer-backend/company-profile/financial-document/financial-document.component';
import { MarketsComponent } from 'src/app/components/pages/markets/markets.component';
import { MarketInfoComponent } from 'src/app/components/pages/markets/market-info/market-info.component';
import { MarketInfoNoviceComponent } from 'src/app/components/pages/markets/market-info-novice/market-info-novice.component';
import { MarketGuard } from 'src/app/gaurds/marketGaurd/market.guard';
import { MarketDetailsComponent } from 'src/app/components/pages/markets/market-details/market-details.component';
import { BronzeComponent } from 'src/app/components/pages/profile/verification/bronze/bronze.component';
import { DocumentCenterComponent } from 'src/app/components/content-page/document-center/document-center.component';
import { UserRegisterStepsComponent } from 'src/app/components/pages/user-register-steps/user-register-steps.component';
import { AccountComponent } from 'src/app/components/pages/profile/account/account.component';
import { ProfileProComponent } from 'src/app/components/pages/profile/profile-pro/profile-pro.component';
import { IboCompanyInformationComponent } from 'src/app/components/issuer-backend/company-profile/ibo-company-information/ibo-company-information.component';
import { GetInTouchComponent } from 'src/app/components/pages/get-in-touch/get-in-touch.component';
import { ListingComponent } from '../../components/content-page/listing/listing.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { name: 'home' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { name: 'login' },
    canActivate: [AuthenticationOffGuard]
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: { name: 'registration' },
    canActivate: [AuthenticationOffGuard]
  },
  {
    path: 'user-registration',
    component: UserRegisterComponent,
    data: { name: 'user-registration' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'issuer-registration',
    component: IssuerRegisterComponent,
    data: { name: 'issuer-registration' },
    canActivate: [AuthenticationOffGuard]
  },
  {
    path: 'issuerRegister',
    component: IssuerRegisterWizardComponent,
    canActivate: [AuthenticationGuard, IssuerRegisterGuard],
    data: { name: 'issuerRegister' }
  },
  {
    path: 'issuerRegister/:id',
    component: IssuerRegisterWizardComponent,
    // canActivate: [AuthenticationGuard, IssuerRegisterGuard],
    data: { name: 'issuerRegister' }
  },
  {
    path: 'user-register-steps/:id',
    component: UserRegisterStepsComponent,
    // canActivate: [AuthenticationGuard, IssuerRegisterGuard],
    data: { name: 'issuerRegister' }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: { name: 'about-us' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'learning-center',
    component: LearningCenterComponent,
    data: { name: 'learning-center' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'document-center',
    component: DocumentCenterComponent,
    data: { name: 'document-center' },
    canActivate: [IssuerRegisterOffGuard]
  },
    {
        path: 'listing',
        component: ListingComponent,
        data: { name: 'listing' },
        canActivate: [IssuerRegisterOffGuard]
    },
  {
    path: 'ibo',
    component: IboComponent,
    data: { name: 'ibo' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'ibo-company/:id',
    component: IboCompanyComponent,
    data: { name: 'ibo-company' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'accountActivation',
    component: AccountActivationComponent,
    data: { name: 'accountActivation' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'tmpEmailUpdate',
    component: ChangeEmailComponent,
    data: { name: 'tmpEmailUpdate' }
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    data: { name: 'forgotPassword' },
    canActivate: [AuthenticationOffGuard]
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
    data: { name: 'resetPassword' },
    canActivate: [AuthenticationOffGuard]
  },
  {
    path: 'contact',
    component: ContactUsComponent,
    data: { name: 'contact' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'legal',
    component: LegalComponent,
    data: { name: 'legal' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'legal/:alias',
    component: LegalDetailContentComponent,
    data: { name: 'legal-detail' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'press-release/:alias',
    component: PressReleaseDetailComponent,
    data: { name: 'press-release' },
    canActivate: [IssuerRegisterOffGuard]
  },
  {
    path: 'press-center',
    component: PressCenterComponent,
    data: { name: 'press-center' },
    canActivate: [IssuerRegisterOffGuard],
    canActivateChild: [IssuerRegisterOffGuard],
    children: [
      { path: '', component: PressContentComponent, pathMatch: 'full' },
      { path: 'bexPressImage', component: PressImageComponent },
      { path: 'dLogo', component: DLogoComponent }
    ]
  },
  {
    path: 'markets',
    component: MarketsComponent,
    data: { name: 'markets' }
  },
  { path:'market-details/:id/:idStock',
    component:MarketDetailsComponent,
    data: { name: 'market-details' }
  },
  {
    path: 'market-info/:id',
    component: MarketInfoComponent,
    canActivate: [MarketGuard],
    data: { name: 'market-info' }
  },
  {
    path: 'market-info-pro/:id',
    component: MarketInfoComponent,
   // canActivate: [MarketGuard],
    data: { name: 'market-info-pro' }
  },
  {
    path: 'market-info-novice/:id',
    component: MarketInfoNoviceComponent,
    canActivate: [MarketGuard],
    data: { name: 'market-info-novice' }
  },
  {
    path: 'market-info-mobile/:id',
    component: MarketInfoComponent,
    canActivate: [MarketGuard],
    data: { name: 'market-info-mobile' }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivateChild: [AuthenticationGuard, IssuerRegisterOffGuard],
    canActivate: [AuthenticationGuard, IssuerRegisterOffGuard],
    data: { name: 'profile' },
    children: [
      { path: '', redirectTo: '/profile/account', pathMatch: 'full' },
      { path: 'security', component: SecurityComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'account', component: AccountComponent },
      { path: 'profile-pro', component: SettingsComponent },
      { path: 'verification-level', component: VerificationComponent },
      { path: 'bronze', component: BronzeComponent },
      { path: 'silver', component: SilverComponent },
      { path: 'gold', component: GoldComponent },
    ]
  },
  {
    path: 'issuer-profile',
    component: IssuerProfileComponent,
    canActivateChild: [AuthenticationGuard, IssuerRegisterOffGuard],
    canActivate: [AuthenticationGuard, IssuerRegisterOffGuard],
    data: { name: 'issuer-profile' },
    children: [
      { path: '', redirectTo: '/issuer-profile/company-information', pathMatch: 'full' },
      { path: 'company-information', component: CompanyInformationComponent },
      { path: 'company-description', component: CompanyDescriptionComponent },
      { path: 'management-information', component: ManagementInformationComponent },
      { path: 'financial-documents', component: FinancialDocumentComponent },
      { path: 'ibo', component: IboCompanyInformationComponent },
    ]
  },
  {
    path: 'wallet',
    component: WalletComponent,
    canActivateChild: [AuthenticationGuard, IssuerRegisterOffGuard],
    canActivate: [AuthenticationGuard, IssuerRegisterOffGuard],
    data: { name: 'wallet' },
    children: [
      { path: '', redirectTo: '/wallet/balance', pathMatch: 'full' },
      { path: 'balance', component: BalanceComponent, data: { name: 'balance' } },
      { path: 'history', component: HistoryComponent, data: { name: 'history' } },
      { path: 'order', component: OrdersComponent, data: { name: 'order' } }
    ]
  },
  {
    path: 'privacy-policy',
    component: ComingSoonComponent,
    canActivate: [IssuerRegisterOffGuard],
    data: { name: 'privacy-policy' }
  },
    {
        path: 'get-in-touch',
        component: GetInTouchComponent,
        data: { name: 'get-in-touch' }
    },
  {
    path: 'page-not-found',
    component: Error404Component,
    data: { name: 'error' }
  },
  {
    path: '**',
    component: Error404Component,
    data: { name: 'error' }
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [
    RouterModule
  ],
  providers: []
})
export class BatpRoutingModule { }

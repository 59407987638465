import { Component, OnInit, Input } from '@angular/core';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { Store, select } from '@ngrx/store';
import * as rootStore from '../../../app.reducer';
import * as Auth from '../../../shared/states/authentication/auth.action';
import { UtilityService } from '../../../services/utilities/utility.service';
import { hyb_udata, hyb_udetail, hyb_compdetail } from '../../../shared/data/constant-data';
import * as AuthData from '../../../shared/states/auth-data/auth-data.action';
import { LogoutService } from '../../../services/logout/logout.service';
import { SessionOutService } from '../../../services/sessionOut/session-out.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/mergeMap';
import { StandaloneService } from '../../../services/standalone/standalone.service';
import { headerMenuID } from '../../../shared/data/menu-data';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/localstorage/storage.service';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'batp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {

  constructor(private _req: ApirequestService,
    private _urls: ApiUrlService,
    private _utils: UtilityService,
    private _lo: LogoutService,
    private _ls: StorageService,
    private _logIn: LoginService,
    private _so: SessionOutService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _standalone: StandaloneService,
    private _sb: SnackbarService,
    private router: Router,
    private store: Store<rootStore.State>) {

  }
  menuItems: any = [];
  isAuthenticated: boolean = false;
  userData: any = [];
  userDetails: any = [];
  showLoader: boolean = false;
  currentRouteData: any = '';
  logOutLoader: boolean = false;
  env: string = environment.env;
  isMobile: boolean = false;
  menuStatus = false;
  desktopMenuStatus = false;

  getMenuItems() {
    let data = {
      id: headerMenuID
    }
    this._req.fetchApiData(this._urls.menuItemsByNameUrl, data, true).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          this.menuItems = resSucc;
          //this.getMenuTypes(this.menuItems);
        }
        if (resErr != '') {
          this._so.handleSessionOutErr(resErr);
        }

      },
      error => {

      },
      () => {

      }
    );
  }
  navToPage(menu) {
    this._router.navigate([menu.url]);
    this.desktopMenuStatus = false;
  }

  isCurrentMenu(menu) {
    return this.currentRouteData == menu.url;
  }

  menuToggle(bool) {
    this.menuStatus = bool;
  }
  desktopMenuToggle(bool) {
      this.desktopMenuStatus = bool;
  }

  logOut() {
    this.logOutLoader = true;
    this._lo.logOut();
    this._lo.logOutLoader.subscribe(
      data => {
        if (data) {
          this.logOutLoader = false;
          this.menuStatus = false;
        }
      }
    )
  }
  getAuthDetail() {
    this.store.pipe(select('auth')).subscribe(
      (data: any) => {
        this.isAuthenticated = data.isAuthenticated;
        if (!data.isAuthenticated) {
          let userData = this._utils.getFromStorage(hyb_udata);
          let userDetails = this._utils.getFromStorage(hyb_udetail);
          let companyDetails = this._utils.getFromStorage(hyb_compdetail);
          this.isAuthenticated = data.isAuthenticated;
          if (userData) {
            this.isAuthenticated = (userData.isLogin == '1') ? true : false;
            this.addToStore(userData, userDetails, companyDetails);
            this.getUserAccDetails();
          }
        } else if (data.isAuthenticated == true) {
          setTimeout(() => {
            this.getUserAccDetails();
          }, 100);
        }
      }
    );
  }
  getUserDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data: any) => {
        this.userDetails = data.userDetail;

        // this.preferredTheme = this.userDetails.preferredTheme == true ? true : false;
        // this._ls.setLocalItem('preferredTheme',this.preferredTheme);
        // this._logIn.preferredTheme.next(this.preferredTheme);
      }
    );
  }
  addToStore(userData, userDetails, companyDetails) {
    this.store.dispatch(new Auth.LoginAuthentication());
    this.store.dispatch(new AuthData.UserAuthenticationData(userData));
    this.store.dispatch(new AuthData.UserAuthenticationDetails(userDetails));
    // if(companyDetails || companyDetails.hasOwnProperty('company')) this.store.dispatch( new AuthData.IssuerCompanyDetails(companyDetails));
    if (companyDetails) this.store.dispatch(new AuthData.IssuerCompanyDetails(companyDetails));
  }
  disableWallet() {
    this._sb.openSnackBar('Realnet Wallet disabled', 3000, 'batp_error_snackbar', 'right', 'top');
  }
  @Input() paramIdHdr: any;
  getMarketRoute(route) {
    let url = [];
    if (this.stockList.length > 0) {
      setTimeout(() => {
        // if(typeof this.paramIdHdr == 'undefined')
        this.paramIdHdr = this.stockList[0].idCompanyStock;
        url = [route, this.paramIdHdr];
        this._ls.setLocalItem('latestView', url[0]);
        this._router.navigate(url);
        this.menuToggle(false);
      }, 400);
    }
  }
  toggleMobSubMenu: boolean = false;
  toggleMobDDMenu() {
    this.toggleMobSubMenu = !this.toggleMobSubMenu;
  }
  navToCompany() {

  }
  stockList: any = [];
  getStockList() {
    let data = {};
    if (this.userData != '') data = { idUser: this.userData.idUser };
    this._req.fetchApiData(this._urls.stockListUrl, data, true).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          this.stockList = resSucc;
        }
      }
    )
  }
  getHeaderStat() {
    let status;
    if (this.currentRouteData != 'accountActivation' &&
      this.currentRouteData != 'tmpEmailUpdate') {
      status = false;
    } else {
      status = true;
    }
    return status;
  }

  preferredTheme: boolean = true;
  toggleTheme() {

    let data = {
      preferredTheme: this.preferredTheme ? false : true
    };
    this._req.fetchApiData(this._urls.changeThemeUrl, data, false).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          this.preferredTheme = !this.preferredTheme;
          this._logIn.getUserDetails();
          this._ls.setLocalItem('preferredTheme', this.preferredTheme);
          this._logIn.preferredTheme.next(this.preferredTheme);
        }
        if (resErr != '') {
          this._so.handleSessionOutErr(resErr);
        }
      },
      error => {

      },
      () => {

      }
    )
  }

  userAccDetials: any = [];
  getUserAccDetails() {
    this._req.fetchApiData(this._urls.userAccountDetailsUrl, {}, false).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          this.userAccDetials = resSucc;
          this.getWltBal(this.userAccDetials);
        }
      },
      error => {

      },
      () => {

      }
    )
  }

  wltBalUSD: any = 0;
  wltBalBTC: any = 0;
  getWltBal(data) {
    this.wltBalUSD = 0; this.wltBalBTC = 0;
    data.forEach((ele) => {
      this.wltBalUSD = this.wltBalUSD + ele.totalinUSD;
      this.wltBalBTC = this.wltBalBTC + ele.totalinBTC;
    });
  }



  toggleMarketProWallet() {
    this._utils.doubleWalletToggle.next(!this.walletToggle);
  }

  walletToggle: boolean = false;
  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
    this.getMenuItems();
    this.getAuthDetail();
    this.getUserDetails();
    this.getStockList();
    this._standalone.getNemIncoming();
    this._utils.doubleWalletToggle.subscribe(
      data => this.walletToggle = data
    )
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentRouteData = this._route.root.firstChild.snapshot.data['name'];
    });
  }

}

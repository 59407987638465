import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as rootReducer from '../../app.reducer';


@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {


  constructor(public route: Router,
    public store: Store<rootReducer.State>) { }

  canActivate() {
    let authenticated;
    this.store.pipe(select('auth')).subscribe(
      (data: any) => {
        authenticated = data.isAuthenticated;
      }
    );
    if (authenticated) {
      return true;
    } else {
      this.route.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }


}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatumService } from 'src/app/services/datum/datum.service';

@Component({
  selector: 'batp-home-video',
  templateUrl: './home-video.component.html',
  styleUrls: ['./home-video.component.css']
})
export class HomeVideoComponent implements OnInit {

  constructor(private _datum:DatumService) { }

  getDemoUrl( prod: boolean = true ) {
    let url = environment.demoUrl + this.userData.demoToken;
    //let url = 'https://demostage.business-software.in?demoToken=' + this.userData.demoToken;
    return url;
  }
  userData:any;
  getUserData() {
    this.userData = this._datum.getUserLoginData();
  }

  ngOnInit() {
    this.getUserData();
  }

}

import { Component, OnInit } from '@angular/core';


declare var $:any;
@Component({
  selector: 'batp-news-ticker',
  templateUrl: './news-ticker.component.html',
  styleUrls: ['./news-ticker.component.css']
})
export class NewsTickerComponent implements OnInit {

  constructor() { }

  initiateTicker() {
    setTimeout(()=>{
      $('.ticker_carousel').carouselTicker();
    },100);
  }


  ngOnInit() {
    this.initiateTicker();
  }

}

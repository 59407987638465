import { Component, OnInit } from '@angular/core';
import { PageContentService } from '../../../services/pageContent/page-content.service';
import { legalCatId, legalAlias } from '../../../shared/data/content-page-data';

@Component({
  selector: 'batp-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  constructor(private _ps:PageContentService) { }
  legalContent:any = [];
  showLoader:boolean = true;
  getLegalCont() {
    this._ps.getContentByCategory(legalCatId,legalAlias);
    this._ps.legalContent
      .subscribe(
        data => {
          if(data) {
            this.legalContent = data;
            this.showLoader = false;
          }
        }
      );
  }

  ngOnInit() {
    this.getLegalCont()
  }

}

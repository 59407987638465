import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatumService } from 'src/app/services/datum/datum.service';

@Component({
  selector: 'batp-register-payment-method',
  templateUrl: './register-payment-method.component.html',
  styleUrls: ['./register-payment-method.component.css']
})
export class RegisterPaymentMethodComponent implements OnInit {

  constructor(private _router: Router, private _datum: DatumService) { }
  @Input() regData: any;
  @Output() regStat = new EventEmitter();
  elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string;
  userDetails;
  toggleSection: any = {
    paymentSec: true,
    bankTransfer: false,
    dimCurrency: false
  }
  navToPayment(sec) {
    this.toggleSection['paymentSec'] = false;
    this.toggleSection[sec] = true;
  }
  showBackBtn: boolean = false;
  wizardStepper: any = [
    {
      step: 1,
      active: true,
      completed: false,
      stepSection: true
    },
    {
      step: 2,
      active: false,
      completed: false,
      stepSection: false
    },
  ];

  gotoNext(sec, prev: boolean = false) {
    this.progressStep(sec, prev)
  }
  progressStep(step, prev: boolean = false) {
    this.wizardStepper.forEach(ele => {
      ele.stepSection = false; ele.active = false;
    });
    this.wizardStepper[step].stepSection = true;
    this.wizardStepper[(step == 0) ? step : step - 1].completed = !prev ? true : false;
    this.wizardStepper[step].active = true;
  }
  // completeRegister() {
  //   this.regStat.emit(true);
  // }
  completeRegStep() {
    this._router.navigate(['/']);
  }

  // getRegStat(e) {
  //   this.regStat.emit(e);
  // }
  ngOnInit() {
    this.getUserDetails();
  }

  getUserDetails() {
    this.userDetails = this._datum.getUserDetails();
    this.value = this.userDetails.internalAddress;
  }
}

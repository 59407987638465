import { Injectable } from '@angular/core';
import { ApirequestService } from '../apirequest/apirequest.service';
import { ApiUrlService } from '../api-url/api-url.service';
import { LogoutService } from '../logout/logout.service';
import { sesssionExpired, hyb_udata } from '../../shared/data/constant-data';
import { StorageService } from '../localstorage/storage.service';
import * as rootState from '../../app.reducer';
import { Store, select } from '@ngrx/store';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})

export class HeartBeatService {

  constructor(private _req: ApirequestService,
    private _lstore: StorageService,
    private _ls: LoginService,
    private _lo: LogoutService,
    private store: Store<rootState.State>,
    private _urls: ApiUrlService) { }

  heartBeat() {
    let userData: any = this._lstore.getLocalItems(hyb_udata);
    // this.store.pipe(select('auth')).subscribe(
    //   (data:any) => {
    //     if(data.isAuthenticated) {
    //       this._req.fetchApiData(this._urls.heartBeatUrl,{},false).subscribe(
    //         (data:any) => {
    //           let resSucc = data;
    //           if(resSucc.codeNo == '0') {
    //             this._lo.logOut(sesssionExpired);
    //           }
    //         }
    //       )
    //     }
    //   }
    // );

    let authenticated = this._ls.getAuthenticated();
    if (userData) {
      this._req.fetchApiData(this._urls.heartBeatUrl, {}, false).subscribe(
        (data: any) => {
          let resSucc = data;
          if (resSucc.codeNo == '0') {
            this._lo.logOut(sesssionExpired);
          }
        }
      );
    }

  }


}

import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'batp-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.css']
})
export class BankTransferComponent implements OnInit {

  constructor() { }
  @Output() registerStatus = new EventEmitter();
  @Input() address:string = '';
  @Input() showBtn:boolean = true;
  completeRegister() {
    this.registerStatus.emit(true);
  }



  ngOnInit() {
  }

}

import { Component, OnInit, Input, Output } from '@angular/core';
import { AuthService } from "angular-6-social-login-v2";
import { FacebookLoginProvider, GoogleLoginProvider, LinkedinLoginProvider } from "angular-6-social-login-v2";
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { LoginService } from '../../../services/login/login.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'batp-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.css']
})
export class SocialLoginComponent implements OnInit {
  @Input() loginType:string = 'login';
  @Input() styleType:string = 'default';
  @Output() homeSlStat:any = new Subject();
  constructor(private _smlAuth: AuthService,
              private _lo:LoginService,
              private _url:ApiUrlService,
              private _req:ApirequestService) { }
  smLogin(media) {
    switch (media) {
      case 'fb':
        this.fbLogin();
        break;
      case 'gplus':
        this.gplusLogin();
        break;
      case 'linkedIn':
        this.linkedInLogin();
        break;
      default:
        break;
    }
    return false;
  }
  fbLogin() {

    this._smlAuth.signIn(FacebookLoginProvider.PROVIDER_ID).then((res)=>{
      let data = {
        email: res.email,
        fbId: res.id,
        userType: 'Investor',
        social: 'fb'
      }
      this.socialRegister(data);
    });
  }
  gplusLogin() {
    this._smlAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then((res)=>{
      let data = {
        email: res.email,
        googleId: res.id,
        userType: 'Investor',
        social: 'google'
      }
      this.socialRegister(data);
    });
  }
  linkedInLogin() {
    this._smlAuth.signIn(LinkedinLoginProvider.PROVIDER_ID).then((res)=>{
      let data = {
        email: res.email,
        linkedinId: res.token,
        userType: 'Investor',
        social: 'linkedin'
      }
      this.socialRegister(data);
    });
  }

  socialRegister(res) {
    let url = (this.loginType == 'login') ? this._url.socialLoginUrl : this._url.socialRegisterUrl;
    let data = res;
    this.homeSlStat.next({
      loader: true,
      error: {}
    });
    this._req.fetchApiData(url,data).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this._lo.setLoginAction(resSucc);
        }
        if(resErr != '') {
          this.homeSlStat.next({
            loader: false,
            error: resErr
          });
        }
      },
      error => {

      },
      () => {

      }
    )
  }





  ngOnInit() {

  }

}

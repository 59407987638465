import { Component, OnInit } from '@angular/core';
import { AccountDetailsService } from '../../../../services/accountDetails/account-details.service';
import { UtilityService } from '../../../../services/utilities/utility.service';
import { trigger, transition, state, style, animate } from '@angular/animations';


@Component({
  selector: 'batp-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ])
    ])
  ]
})
export class OrdersComponent implements OnInit {

  constructor(private _accDetail:AccountDetailsService, 
    private _utils:UtilityService) { }
  ordersSec:any = {
    activeOrder: true,
    matchedOrders: false
  }

  toggleSection(prop) {
    switch (prop) {
      case 'activeOrder':
        this.ordersSec = { activeOrder: true, matchedOrders: false }
        break;
      case 'matchedOrders':
        this.ordersSec = { activeOrder: false, matchedOrders: true }
        break;
      default:
        break;
    }
  }

  dataLoaded:boolean = false
  activeShowLoader:boolean = true;
  activeOrders:any = [];
  getActiveOrder() {
    this._accDetail.getTradeHistory('Active');
    this._accDetail.activeOrder
    .subscribe( data => {
      if(data != 'error') {
        if(data.length > 0) {
          this.dataLoaded = true;
          this.activeShowLoader = false;
          this.activeOrders = data;
        } else {
          this.dataLoaded = true;
          this.activeShowLoader = false;
          this.activeOrders = [];
        }
      } else if( data == 'error' ) {
        this.dataLoaded = true;
        this.activeShowLoader = false;
      }
    });
  }

  matchDataLoaded:boolean = false
  matchShowLoader:boolean = true;
  matchedOrders:any = [];
  getMatchedOrder() {
    this._accDetail.getTradeHistory('Matched');
    this._accDetail.matchedOrder
    .subscribe( data => {
      if(data != 'error') {
        if(data.length > 0) {
          this.matchDataLoaded = true;
          this.matchShowLoader = false;
          this.matchedOrders = data;
        }
      } else if( data == 'error' ) {
        this.matchDataLoaded = true;
        this.matchShowLoader = false;
      }
    });
  }

  addFee(total, fee) {
    return (parseFloat(total) + parseFloat(fee));
  }

  getPrimaryAsset(assetname) {
    let assetparts = assetname.split('-');
    return assetparts[1];
  }

  ngOnInit() {
    this.getActiveOrder();
    this.getMatchedOrder();
  }

}

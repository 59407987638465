import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validatePrice } from 'src/app/shared/form-validators';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { MarketsService } from 'src/app/services/markets/markets.service';

@Component({
  selector: 'batp-limit-sell',
  templateUrl: './limit-sell.component.html',
  styleUrls: ['./limit-sell.component.css']
})
export class LimitSellComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
  private _fb: FormBuilder,
  private _urls: ApiUrlService,
  private _req: ApirequestService,
  private _sb: SnackbarService,
  private _market: MarketsService,
  private dialogRef:MatDialogRef<LimitSellComponent>) { }
  buyOrderLimitForm: FormGroup; 
  sellOrderLimitForm: FormGroup;
  commissionFee = 0.25;
  sellOrderLimitSubmitted:boolean = false;
  sellOrderLoader:boolean = false;
  paramID: any;
  sellOrderLimitFormInit() {
    this.sellOrderLimitForm = this._fb.group({
      askPrice: ['', [Validators.required, validatePrice]],
      askSize: ['', [Validators.required, validatePrice]],
      askTotal: ['', [Validators.required, validatePrice]],
      commissionFee: ['0.000001'],
      totalWithComission: ['', []]
    });
  }
  getTotal(type, price, size, formName: string = 'market') {
    let orderPrice, orderSize, askTotal, totalCommission;
    let form;
    // if (stopLimit) {
    //   form = type == "buy" ? this.sellOrderStopLimitForm : this.sellOrderStopLimitForm;
    // }
    switch (formName) {
      case 'limit':
        form = type === 'buy' ? this.buyOrderLimitForm : this.sellOrderLimitForm;
        break;
      default:
        break;
    }
    const total = type === 'buy' ? 'askTotal' : 'askTotal';
    orderPrice = form.controls[price].value;
    if (formName === 'market') { orderPrice = this.data.stockInfo.priceCurrent; }
    orderSize = form.controls[size].value;
    askTotal = orderPrice * orderSize;
    totalCommission = (this.commissionFee / 100) * askTotal;
    if (!isNaN(orderSize) && orderSize !== '') {
      const valIncComm = totalCommission + askTotal;
      form.controls[total].setValue((valIncComm).toFixed(6));
      const commisionVal: any = 0.000001 > (valIncComm - askTotal) ? 0.000001 : (valIncComm - askTotal);
      form.controls['commissionFee'].setValue((commisionVal).toFixed(6));
      // form.controls['totalWithComission'].setValue(totalCommission + askTotal);
    } else {
      form.controls[total].setValue('');
      form.controls[total].setValue(0);
      form.controls['commissionFee'].setValue('0.000001');
      // form.controls['totalWithComission'].setValue('');
    }
  }
  sellOrderLimitSubmit() {
    this.sellOrderLimitSubmitted = true;
    if (this.sellOrderLimitForm.valid) {
      this.sellOrderLoader = true;
      const formVal = this.sellOrderLimitForm.value;
      const data = {
        type: 'limit',
        stop: 0,
        askPrice: formVal.askPrice,
        askSize: formVal.askSize,
        idCompanyStock: this.data.stockInfo.idCompanyStock,
        idCompany: this.data.stockInfo.idCompany
      };
      this.sellBuySubmitReq(
        this._urls.sellOrderUrl,
        data,
        'sellOrderLimitForm'
      );
    }
  }
  sellBuySubmitReq(url, data, form, type = 'buy') {
    this._req.fetchApiData(url, data, false).subscribe(
      (data: any) => {
        const resSucc = data.data;
        const resErr = data.error;

        if (resSucc !== '') {
         // this.sellOrderLoader = false;
          this.sellOrderLoader = false;
          this._sb.openSnackBar(
            'Your Order Has Been Placed Successfully',
            3000,
            'batp_success_snackbar',
            'center',
            'top'
          );
          this.sellOrderLimitForm.controls['askSize'].setValue('');
          this.sellOrderLimitForm.controls['askTotal'].setValue('');
          this.sellOrderLimitForm.controls['commissionFee'].setValue('0.000001');
          this.dialogRef.close();
          // this.getStockLists();
          // this._market.getStockInfo(this.paramID);
          // this.getStockInfo();
          this.sellOrderLimitSubmitted = false;
        }
        if (resErr !== '') {
          this._sb.openSnackBar(
            resErr['Error Description'],
            3000,
            'batp_error_snackbar',
            'center',
            'top'
          );
        }
      },
      error => { },
      () => {
       // this.sellOrderLoader = false;
        this.sellOrderLoader = false;
      }
    );
  }
  ngOnInit() {
   this.sellOrderLimitFormInit();
   this.sellOrderLimitForm.controls['askPrice'].setValue(this.data.askPrice);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { countries } from 'src/app/shared/data/countries-bk';
import { Countries } from 'src/app/shared/data/countries';
import { validWebsite, validateEmail, validPhoneNumber } from 'src/app/shared/form-validators';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { FsUploadService } from 'src/app/services/fs-upload/fs-upload.service';
import { SessionOutService } from 'src/app/services/sessionOut/session-out.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { LoginService } from 'src/app/services/login/login.service';
import { Store, select } from '@ngrx/store';
import * as rootState from '../../../../app.reducer';
import { UtilityService } from 'src/app/services/utilities/utility.service';

@Component({
  selector: 'batp-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.css']
})
export class CompanyInformationComponent implements OnInit {

  constructor(private _fb:FormBuilder,
              private store:Store<rootState.State>,
              private _fs: FsUploadService,
              private _req:ApirequestService,
              private _so: SessionOutService,
              private _sb:SnackbarService,
              private _utils: UtilityService,
              private _ls:LoginService,
              private _url:ApiUrlService) { }

  showLoader:boolean = false;
  countriesList:Countries[] = countries;
  companyInfoForm:FormGroup;
  companyInfoFormInit() {
    this.companyInfoForm = this._fb.group({
      companyName: ['',[Validators.required]],
      website: ['',[Validators.required,validWebsite]],
      companyEmail: ['',[Validators.required,validateEmail]],
      country: ['',[Validators.required]],
      address1: ['',[Validators.required]],
      address2: [''],
     // city: ['',[Validators.required]],
      zipcode: ['',[Validators.required]],
      facebook: ['',[]],
      twitter: ['',[]],
      linkedin: ['',[]],
    //  googleplus: ['',[]],
      legalSeat: ['',[]]
    })
  }
  companyDetails:any;
  getCompanyDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        this.companyDetails = data.companyDetail;
        this.setCompanyInformation(this.companyDetails);
      }
    );
  }
  setCompanyInformation(data) {
    if(data.companyDocument.length > 0)
    {
      this.getDocumentDetails(data.companyDocument);
    }
    this.companyInfoForm.patchValue({
      companyName: data.company.companyName,
      website: data.company.website,
      companyEmail: data.company.companyEmail,
      country: data.company.country,
      address1: data.company.address1,
      address2: data.company.address2,
    //  city: data.company.city,
      zipcode: data.company.zipcode,
      phone1: data.company.phone1,
      twitter: data.company.twitter,
      facebook: data.company.facebook,
    //  googleplus: data.company.googleplus,
      linkedin: data.company.linkedin
    });
  }
  logoDetails:any = [];
  getDocumentDetails(docs) {
    if (docs.length != 0) {
      this.companyLogoDetails = this._utils.filteredObj(docs,'docTitle','logo');
      this.logoDetails =   this._utils.filteredObj(docs,'docTitle','logo');
    }
  }
  boxEdit: any = {
    businessNameBox: false,
    logoBox: false,
    contactBox: false,
    addressBox: false,
    telephoneBox: false,
    socailMediaBox: false
  }
  editSectionBox(box) {
    this.boxEdit[box] = !this.boxEdit[box];
  }

  editMode:boolean = false;
  updateProfile() {
    this.editMode = true;
  }

  logoImgUrl:string = '';
  uploadCompLogo() {
    let fileAccepted = ["image/*"];
    let maxSize = 2097152;
    this._fs.uploadFiles(fileAccepted,maxSize).then((res) => {
      if (res.filesUploaded.length > 0) {
        this.fileUploadHandler(res);
      }
    });
  }

  companyLogoDetails:any = [];
  fileUploadHandler(res) {
    let filesFailed = res.filesFailed;
    let fileUploaded = res.filesUploaded;
    this.companyLogoDetails = [];
    if (fileUploaded.length > 0 ) {
      this.companyLogoDetails[0] = {
        docTitle: 'logo',
        docName: fileUploaded[0].url,
        docType: 1
      };
      // this.companyInfoForm.controls['companyLogo'].setValue(fileUploaded[0].url);
      this.updateDetails('logoBox');
    }
    if (filesFailed.length > 0 ) {

    }
  }


  saveProfile() {
    if(this.companyInfoForm.valid) {
      this.showLoader = true;
      let formVal = this.companyInfoForm.value;
      this.companyLogoDetails[0].idCompanyDocument = this.logoDetails[0].idCompanyDocument;
      let data = {
        ...formVal,
        id: this.companyDetails.company.idCompany,
        documentInfo: this.companyLogoDetails
      }
      this._req.fetchApiData(this._url.companyUpdateUrl,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          setTimeout(()=>{ this.showLoader = false; },1500);
          if(resSucc != '') {
            this._ls.getCompanyDetails(this.companyDetails.company.idCompany);
            this._sb.openSnackBar('Your Company Information Has Been Updated Successfully',3000,'batp_success_snackbar','center','top');
          }

          if(resErr != '') {
            this._so.handleSessionOutErr(resErr);
          }
        },
        error => {

        },
        () => {

        }
      )
    }
  }
  updateDetails(box) {
    let data:any;
    let succMsg:any;
    this.showLoader = true;
    if (box == 'businessNameBox') {
      data = {
        companyName: this.companyInfoForm.value.companyName,
        id: this.companyDetails.company.idCompany
      };
      succMsg = 'Your Company Name Has Been Updated Successfully';
    } else if (box == 'contactBox') {
      data = {
        website: this.companyInfoForm.value.website,
        companyEmail: this.companyInfoForm.value.companyEmail,
        id: this.companyDetails.company.idCompany};
      succMsg = 'Your Company website Has Been Updated Successfully';
    } else if (box == 'addressBox') {
      data = {
        address1: this.companyInfoForm.value.address1,
        address2: this.companyInfoForm.value.address2,
        country: this.companyInfoForm.value.country,
        zipcode: this.companyInfoForm.value.zipcode,
        legalSeat:this.companyInfoForm.value.legalSeat,
        id: this.companyDetails.company.idCompany};
      succMsg = 'Your Address details Has Been Updated Successfully';
    } else if (box == 'socailMediaBox') {
      data = {
        twitter: this.companyInfoForm.value.twitter,
        facebook: this.companyInfoForm.value.facebook,
        linkedin: this.companyInfoForm.value.linkedin,
        companyEmail: this.companyInfoForm.value.companyEmail,
        id: this.companyDetails.company.idCompany};
      succMsg = 'Your Social media Has Been Updated Successfully';
    } else if (box == 'logoBox') {
      // this.companyLogoDetails[0].idCompanyDocument = this.logoDetails[0].idCompanyDocument;
      data = {
        documentInfo: this.companyLogoDetails,
        id: this.companyDetails.company.idCompany
      };
      succMsg = 'Your Logo Has Been Updated Successfully';
    }

    this._req.fetchApiData(this._url.companyUpdateUrl, data, false).subscribe(
      (data:any) => {
        const resSucc = data.data;
        const resErr = data.error;
        setTimeout(()=>{ this.showLoader = false; },1500);
        if(resSucc != '') {
          this._ls.getCompanyDetails(this.companyDetails.company.idCompany);
          this._sb.openSnackBar(succMsg,3000,'batp_success_snackbar','center','top');
          this.boxEdit[box] = !this.boxEdit[box];
        }

        if (resErr != '') {
          this._so.handleSessionOutErr(resErr);
        }
      },
      error => {

      },
      () => {

      }
    );
  }
  ngOnInit() {
    this.companyInfoFormInit();
    this.getCompanyDetails();
  }

}

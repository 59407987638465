import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { businessActivities, bussActivities } from 'src/app/shared/data/business-activities';
import { countries } from 'src/app/shared/data/countries';
import { Countries } from 'src/app/shared/data/countries-bk';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { SessionOutService } from 'src/app/services/sessionOut/session-out.service';
import { validNumber } from 'src/app/shared/form-validators';
import { LoginService } from 'src/app/services/login/login.service';
import { Store, select } from '@ngrx/store';
import * as rootState from '../../../../app.reducer';
import { MatDatepickerInputEvent } from '@angular/material';

export interface Sector {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'batp-company-description',
  templateUrl: './company-description.component.html',
  styleUrls: ['./company-description.component.css']
})

export class CompanyDescriptionComponent implements OnInit {

  constructor(private _fb:FormBuilder,
              private store:Store<rootState.State>,
              private _req:ApirequestService,
              private _sb:SnackbarService,
              private _so:SessionOutService,
              private _ls:LoginService,
              private _url:ApiUrlService) { }



  boxEdit: any = {
    incorpBox: false,
    purposeBox: false,
    stockInfoBox: false,
    securityBox: false,
    marketBox: false,
    sectorBox: false,
    auditorBox: false,
  }
  sectors: Sector[] = [
    {value: 'Initial Production', viewValue: 'Initial Production'},
    {value: 'Industrial Sector', viewValue: 'Industrial Sector'},
    {value: 'Service Sector', viewValue: 'Service Sector'},
    {value: 'Information Sector', viewValue: 'Information Sector'}
  ];
  
  editSectionBox(box) {
    this.boxEdit[box] = !this.boxEdit[box];
  }
  editMode:boolean = false;
  updateProfile() {
    this.editMode = true;
  }
  markets:bussActivities[] = businessActivities;
  countries:Countries[] = countries;
  companyDescForm:FormGroup;
  marketData:any = [];
  companyDescFormInit() {
    this.companyDescForm = this._fb.group({
      companyActivity: ['',[Validators.required]],     
      incorporationCountry:['',[]],
      legalForm:['',[]],
      incorporationYear:['',[]],
      incorporationNumber:['',[]],
      paidinCapital:['',[]],
      shareholdingCapital:['',[]],
      shareValue:['',[]],
      outstandingShares:['',[]],
      shareType:['',[]],
      dimCurrency:['',[]],
      dividentIssueDate:['',[]],
      authorizedShares:['',[]],
      ibin:['',[]],
      isin:['',[]],
      preferredSymbol:['',[]],
      marketplace : this._fb.array( [] ),
      businessActivities:['',[]],
      auditor:['',[]]
    })
  }
  get formData() { return <FormArray>this.companyDescForm.get('marketplace'); }

  createMrktFields(req:boolean = false): FormGroup {
    return this._fb.group({
      marketplaceName: ['']
    });
  }

  addMarketPlace(): void {
    let marketListss = this.companyDescForm.get('marketplace') as FormArray;
    marketListss.push(this.createMrktFields());
  }
  removeMarketPlace(i) {
    let marketListss = this.companyDescForm.get('marketplace') as FormArray;
    marketListss.removeAt(i);
  }
  companyDetails:any;
  getCompanyDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        this.companyDetails = data.companyDetail;
        this.setCompanyDesc(this.companyDetails);        
      }
    );
  }
  setMarketPlace(){
    this.companyDetails.marketplace.forEach((x) => {
      this.formData.push(this._fb.group({
        marketplaceName: [x.marketplaceName, [Validators.required]],
        idMarketplace: [x.idMarketplace]
      }))
    })
  }
  setCompanyDesc(data) {
    this.companyDescForm.patchValue({
      companyActivity:data.companyProfile.companyActivity,     
      incorporationCountry:data.companyProfile.incorporationCountry,
      legalForm:data.company.legalForm,
      incorporationYear:data.companyProfile.incorporationYear,
      incorporationNumber:data.companyProfile.incorporationNumber,
      paidinCapital:data.companyFinancial.paidinCapital,
      shareholdingCapital:data.companyFinancial.shareHoldingCapital,
      shareValue:data.companyFinancial.shareValue,
      outstandingShares:data.companyFinancial.outstandingShares,
      shareType:data.companyFinancial.shareType,
      dimCurrency:data.companyFinancial.dimCurrency,
      dividentIssueDate:data.companyFinancial.dividentIssueDate,
      authorizedShares:data.companyFinancial.authorizedShares,
      ibin:data.companyIbs.ibin,
      isin:data.companyIbs.isin,
      preferredSymbol:data.companyFinancial.preferredSymbol,
      marketplace:data.marketplace,
      businessActivities:data.companyProfile.businessActivities,
      auditor:data.companyFinancial.auditor
    })
  }

  showLoader:boolean = false;
  saveChanges() {
    if(this.companyDescForm.valid) {
      this.showLoader = true;
      let formVal = this.companyDescForm.value;
      let data = {
        ...formVal,
        id: this.companyDetails.company.idCompany
      };
      this._req.fetchApiData(this._url.companyUpdateUrl,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          setTimeout(()=>{ this.showLoader = false; },1500);
          if(resSucc != '') {
            this.editMode = false;
            this._ls.getCompanyDetails(this.companyDetails.company.idCompany);
            this._sb.openSnackBar('Your Company Description Has Been Updated Successfully',3000,'batp_success_snackbar','center','top');
          }

          if(resErr != '') {
            this._so.handleSessionOutErr(resErr);
          }
        },
        error => {

        },
        () => {

        }
      )
    }
  }
  updateDetails(box)
  {
    let data:any;
    let succMsg:any;
    if(box=='incorpBox')
    {
      data = {
      incorporationCountry : this.companyDescForm.value.incorporationCountry,
      incorporationNumber : this.companyDescForm.value.incorporationNumber,
      incorporationYear : this.companyDescForm.value.incorporationYear,
      legalForm : this.companyDescForm.value.legalForm,
      id: this.companyDetails.company.idCompany
      }
      succMsg='Incorporation description has been updated successfully';
    }
    else if(box=='purposeBox')
    {
      data = {
        companyActivity : this.companyDescForm.value.companyActivity,
        id: this.companyDetails.company.idCompany
      }
      succMsg='Incorporation description has been updated successfully';
    }
    else if(box=='stockInfoBox')
    {
      data = {
        shareholdingCapital : this.companyDescForm.value.shareholdingCapital,
        paidinCapital : this.companyDescForm.value.paidinCapital,
        authorizedShares : this.companyDescForm.value.authorizedShares,
        shareValue : this.companyDescForm.value.shareValue,
        outstandingShares : this.companyDescForm.value.outstandingShares,
        shareType : this.companyDescForm.value.shareType,
        dimCurrency: this.companyDescForm.value.dimCurrency,
        dividentIssueDate: this.companyDescForm.value.dividentIssueDate,
        id: this.companyDetails.company.idCompany
      }
      succMsg='Stock information has been updated successfully';
    }
    else if(box=='securityBox')
    {
      data = {
        isin : this.companyDescForm.value.isin,
        ibin : this.companyDescForm.value.ibin,
        preferredSymbol : this.companyDescForm.value.preferredSymbol,
        id: this.companyDetails.company.idCompany
      }
      succMsg='Identification information has been updated successfully';
    }
    else if(box=='sectorBox')
    {
      data = {
        businessActivities : this.companyDescForm.value.businessActivities,
        id: this.companyDetails.company.idCompany
      }
      succMsg='Sector information has been updated successfully';
    }
    else if(box=='marketBox')
    {
      data = {
        marketplace : this.formData.value,
        id: this.companyDetails.company.idCompany
      }
      console.log(data);
      succMsg='Marketplace has been updated successfully';
    }
    else if(box=='auditorBox')
    {
      data = {
        auditor : this.companyDescForm.value.auditor,
        id: this.companyDetails.company.idCompany
      }
      succMsg='Auditor information has been updated successfully';
    }
    this.showLoader = true;
    this._req.fetchApiData(this._url.companyUpdateUrl,data,false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        setTimeout(()=>{ this.showLoader = false; },1500);
        if(resSucc != '') {
          this.editMode = false;
          this._ls.getCompanyDetails(this.companyDetails.company.idCompany);
          this._sb.openSnackBar(succMsg,3000,'batp_success_snackbar','center','top');
          this.boxEdit[box] = !this.boxEdit[box];        
        }

        if(resErr != '') {
          this._so.handleSessionOutErr(resErr);
        }
      },
      error => {

      },
      () => {

      }
    )
  }
  incorporationYear:any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.incorporationYear = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.incorporationYear = new Date(this.incorporationYear);
  }
  primarySymbol:any =[];
  getPrimarycurrencyDetails() {
    this._req.fetchApiData(this._url.primarySymbolUrl,{},false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.primarySymbol = resSucc;
        }

        if(resErr != '') {

        }
      }
    )
  }
  ngOnInit() {
    this.companyDescFormInit();
    this.getCompanyDetails();
    this.setMarketPlace();
    this.getPrimarycurrencyDetails();
  }

}

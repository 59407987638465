import { Injectable } from '@angular/core';
import { ApirequestService } from '../apirequest/apirequest.service';
import { nemApiUrl } from '../../config/batp.config';
import { UtilityService } from '../utilities/utility.service';
import { hyb_udata } from '../../shared/data/constant-data';
import { Observable, Subscription } from 'rxjs';
import 'rxjs/add/observable/empty'

@Injectable({
  providedIn: 'root'
})
export class StandaloneService {

  constructor(private _req:ApirequestService,
              private _utils:UtilityService) { }
  getStandaloneData() {
    let userData = this._utils.getFromStorage(hyb_udata);
    if(userData) {
      let data = {
        address: userData.internalAddress,
        lastNemId: userData.lastNemId
      };
      return this._req.fetchApiData(nemApiUrl,data);
    } else {
      return Observable.empty();
    }

  }

  //Api For Standalone
  standaloneSubs:Subscription;
  getNemIncoming() {
    let userData = this._utils.getFromStorage(hyb_udata);
    this.standaloneSubs = Observable.interval(10000)
      .flatMap(() => this.getStandaloneData())
      .subscribe(data => {
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { validateEmail,validatePassword, checkPassword } from '../../../shared/form-validators';
import { ErrorMessages } from '../../../shared/error-messages';
import { RegisterService } from '../../../services/register/register.service';
import { CryptoService } from '../../../services/crypto/crypto.service';
import { devEnv, captchaKey } from '../../../config/batp.config';
import { iconSet, messageBoxIcon } from '../../../shared/shared.data';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';


declare var $:any;
@Component({
  selector: 'batp-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class UserRegisterComponent implements OnInit {

  constructor(private _fb: FormBuilder,
              private _register: RegisterService,
              private _location: Location,
              private _route: Router,
              private _sb: SnackbarService,
              private _cryp: CryptoService,
  ) { }
              registrationComp:boolean = false;
  wizardStepper:any = [
    {
      step: 1,
      active: true,
      completed: false,
      stepSection: true
    },
    {
      step: 2,
      active: false,
      completed: false,
      stepSection: false
    },
    {
      step: 3,
      active: false,
      completed: false,
      stepSection: false
    }
  ];
  userRegisterForm: FormGroup;
  userRegisterSubmit:boolean = false;
  registerSubmitted:boolean = false;
  errorMessages:any = new ErrorMessages();
  showLoader:boolean = false;
  registerMessage:string = '';
  registerStatus:any = '';
  registerResult:any = '';
  messIcons:iconSet = messageBoxIcon;
  captchaKey:string = captchaKey;
  initiateRegFlow:boolean = false;
  userLoginFormInit() {
    this.userRegisterForm = this._fb.group({
        'email': ['',[ Validators.required, validateEmail ]],
        'password': ['', [ Validators.required, validatePassword]],
        'googleRecaptcha': ['', devEnv ? [] : [Validators.required]],
        'confirmPassword': ['', [ Validators.required ]],
        'letterCondition': [''],
        'termsCondition': ['', [ Validators.requiredTrue ]],
        'g_recatcha': ['', devEnv ? [] : [Validators.required] ]
      },
      { validator: [checkPassword] }
    );
  }

  userRegister() {
    this.userRegisterSubmit = true;
    if(this.userRegisterForm.valid) {
      this.showLoader = true;
      this._register.registerStatus.next({});
      let formVal:any = this.userRegisterForm.value;
      const data:any = {
        email:formVal.email,
        password: formVal.password,
        googleRecaptcha: formVal.googleRecaptcha,
        register: 'LS'
      };

      const encData = {
          load: this._cryp.encrypt(JSON.stringify(data))
      };
      this._register.register('user', encData);
      this.getStatus();
      //this.getMessages();
    }
  }
  statSubs:any;
  registeredUserID:any = '';
  registerUserData:any;
  getStatus() {
    this.statSubs = this._register.registerStatus.subscribe(
      data => {
        this.registerStatus = data.status;
        if(data.status == true) {
          this.registerMessage = this.errorMessages.userFormSubmission;
          this.loaderStatus();
          // this.registerSubmitted = true;
          this.initiateRegFlow = true;
          this.registerUserData = data.result;
          this.registeredUserID = data.result.idUser;
          this.userRegisterForm.reset();
          //this.showLoader = false;
        } else if( data.status == false ) {
          this.handleRegisterErr(data.result);
          this.showLoader = false;
        }
      }
    );
  }
  regSubmitted($event){
    this.registerSubmitted = $event == true ? true : false;
  }
  loaderSubs:any;
  loaderStatus() {
    this.loaderSubs = this._register.showLoader.subscribe(
      data => {
        this.showLoader = false;
      }
    );
  }

  emailError:boolean = false;
  handleRegisterErr(data) {
    if( data['Error Msg'] == 'emailNotSent' ) {
      this.registerSubmitted = true;
      this.emailError = true;
    }
    this.registerMessage = data['Error Description'];
  }




  messageSubs:any;
  getMessages() {
    this.messageSubs = this._register.registerErrMsg.subscribe(
      data => {
        if( data != '' ) {
          this.registerMessage = data;
        }
      }
    );
  }

  showTip(event) {
    $('.password_tip')[ event=='focus' ? 'fadeIn' : 'fadeOut' ]();
  }

  handleSuccess(event) {
    this.userRegisterForm.controls['googleRecaptcha'].setValue(event);
  }
  getSlStatus($evt) {
    this.showLoader = true;
    if(Object.keys($evt.error).length != 0) {
      this.registerStatus = false;
      this.showLoader = false;
      this.registerMessage = $evt.error['Error Description'];
    }
  }


  resendEmailLoader:boolean = false;
  resendMailSubs:any;
  resendEmail() {
    let data = {
      id: this.registeredUserID
    }
    this.resendEmailLoader = true;
    this._register.resendEmail(data);
    this.resendMailSubs = this._register.resendMailStatus.subscribe(
      data => {
        if(data) {
          if(data.status == true) {
            this.registerMessage = this.errorMessages.userFormSubmission;
            this.resendEmailLoader = false;
            this._sb.openSnackBar( "Resend email sent successfully",5000,'batp_success_snackbar','center','top');
           // this._route.navigate(['/']);
          } else if( !data.status ) {
            this.resendEmailLoader = false;
          }
        }

      },
      error => {

      },
      () => {

      }
    )
  }
  goToPreviousPage()
  {
    this._location.back();
  }



  ngOnInit() {
    this.userLoginFormInit();
  }


  ngOnDestroy() {
    if(typeof this.statSubs != 'undefined' ) this.statSubs.unsubscribe();
    if(typeof this.loaderSubs != 'undefined' ) this.loaderSubs.unsubscribe();
    if(typeof this.messageSubs != 'undefined' ) this.messageSubs.unsubscribe();
    if(typeof this.resendMailSubs != 'undefined' ) this.resendMailSubs.unsubscribe();

  }
}

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

declare var $:any;
@Component({
  selector: 'batp-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }
  @Input() fadeOut:boolean = true;
  @Input() fadeOutTime:number = 3000;
  @Input() message:string = '';
  @Input() icon:string = '';
  @Input() messageType:string = 'success';

  isDismissable() {
    if(this.fadeOut && this.message != '') {
      var seed = this;
      // setTimeout(()=>{
      //   $('.hyb_mess_box').fadeOut(400);
      //   setTimeout(() => {
      //     $('.hyb_mess_box').show();
      //     this.message = '';
      //  },1500);
      // },this.fadeOutTime);
    }
  }

  ngOnInit() {

  }

  ngOnChanges() {
    //this.isDismissable();
  }
  ngDoCheck() {
    this.isDismissable();
  }
}

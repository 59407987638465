import { Component, OnInit } from '@angular/core';
import { FsUploadService } from '../../../services/fs-upload/fs-upload.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { documentsList } from './docs-list';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';

@Component({
  selector: 'batp-update-document',
  templateUrl: './update-document.component.html',
  styleUrls: ['./update-document.component.css']
})
export class UpdateDocumentComponent implements OnInit {

  constructor(private _fs:FsUploadService,
              private _req:ApirequestService,
              private _url:ApiUrlService,
              private _fb:FormBuilder) { }

  showLoader:boolean = false;
  updateDocForm:FormGroup;
  documentList:any = documentsList;
  documentUpldMess:any = '';
  documentUpldStatus:boolean = false;
  updateDocFormInit() {
    this.updateDocForm = this._fb.group({
      'proof_of_sources':['',[Validators.required]],
      'support_document':['',[Validators.required]],
      'support_document_select':['',[Validators.required]],
    })
  }


  documentInfo:any = [];
  uploadDocuments(type,ind) {
    let fileAccepted = [".pdf",".doc",".docx",".docm","image/*"];
    let maxSize = 10485760;
    this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
      if(res.filesUploaded.length > 0) {
        this.fileUploadHandler(type,res.filesUploaded[0]);
        this.setDocumentInfo(res.filesUploaded[0],ind);
      }
    });
  }
  setDocumentInfo(res,ind) {
    this.documentInfo.splice(ind,1,res);
  }



  fileUploadHandler(type,file) {
    this.updateDocForm.controls[type].setValue(file.url);
  }


  getDocumentIcon(file?) {
    let icon = 'fal fa-file-alt';
    if(typeof file != 'undefined') {
      let ext = file.filename.split('.').pop();
      switch (ext) {
        case 'pdf':
          icon = 'fal fa-file-pdf';
          break;
        case 'jpg':
        case 'jpeg':
        case 'png':
          icon = 'fal fa-file-image';
          break;
        default:
          icon = 'fal fa-file-alt';
          break;
      }
    }
    return icon;
  }
  getDocumentDetails() {
    let docList = [{
      docTitle: 'Proof of funds',
      docName: this.updateDocForm.controls['proof_of_sources'].value,
      docType: 'POF'
    },
    {
      docTitle: this.documentList[this.docIndex].docTitle,
      docName: this.updateDocForm.controls['support_document'].value,
      docType: this.documentList[this.docIndex].docType
    }];
    return docList;
  }
  supportDocType:string;
  docIndex:any;
  supDocSelect(evt) {
    this.docIndex = evt.target.value;
    this.supportDocType = this.documentList[this.docIndex].docType;
  }


  updateDocuments() {
    if(this.updateDocForm.valid) {
      this.showLoader = true;
      let data = {
        supportDocType : this.supportDocType,
        documentInfo : this.getDocumentDetails()
      };
      this._req.fetchApiData(this._url.goldVerifyUrl,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
            this.documentUpldMess = 'Your Documents Has Been Updated Successfully';
            this.documentUpldStatus = true;
          }

          if(resErr != '') {
            this.documentUpldMess = resErr['Error Description'];
            this.documentUpldStatus = false;
          }
        },
        error => {

        },
        () => {
          this.showLoader = false;
        }
      )

    }
  }

  ngOnInit() {
    this.updateDocFormInit();
  }

}

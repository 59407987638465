import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {AccountDetailsService} from 'src/app/services/accountDetails/account-details.service';
import {DatumService} from 'src/app/services/datum/datum.service';
import {MatDialog, Sort} from '@angular/material';
import {UtilityService} from 'src/app/services/utilities/utility.service';
import {ModalBoxComponent} from 'src/app/components/widgets/modal-box/modal-box.component';
import {Observable, Subscription} from 'rxjs';
import 'rxjs/add/observable/empty';
import {walletAPIInterval} from 'src/app/shared/data/constant-data';
import {MarketInfoComponent} from '../../../markets/market-info/market-info.component';

// import { SocketService } from "src/app/services/websocket/socket.service";

interface accountDetails {
    primary: any[];
    secondary: any[];
    transactions: any[];
}

declare var $: any;

@Component({
    selector: 'batp-double-wallet',
    templateUrl: './double-wallet.component.html',
    styleUrls: ['./double-wallet.component.css']
})
export class DoubleWalletComponent implements OnInit, OnDestroy {
    count: number = 0;
    public sub: any;
    @Input() styleType: string = 'basic';
    @Input() isPolling: boolean = false;
    @Input() doubleWalletToggle: boolean = false;

    accountDetails: accountDetails = {
        primary: [],
        secondary: [],
        transactions: []
    };
    accountDetailWS: any = {
        primary: [],
        secondary: []
    };
    dataLoaded: boolean = false;
    showLoader: boolean = true;
    isWalletInit: boolean = false;
    isWalletPrimaryProperty: boolean = false;
    isWalletSecondaryProperty: boolean = false;
    actDetailSubs: any;
    userAccSubs: Subscription;
    userLoginData: any;
    secondaryAvailable: number;
    primaryAvailable: number;

    constructor(
        private _accDetail: AccountDetailsService,
        private _datum: DatumService,
        private _dialog: MatDialog,
        private _utils: UtilityService,
        // private socketService: SocketService
    ) {

    }


    ngOnInit() {
        // this.userLoginData = this._datum.getUserLoginData();
        this.getUserDetails();
        // let data = {
        //   idUser: this.userLoginData.idUser,
        //   username: this.userLoginData.username

        // }

        // this.socketService.getUserWallet(data);
        this._utils.doubleWalletToggle.subscribe(
            data => {
                if (data && this.isPolling) {
                    this.getWallet();
                }
            });
        if (!this.isPolling) {
            this.getUserAccountDetails();
        }
    }

    getWallet(): void {
        let walletOrder = [];
        let userWellet = JSON.parse(localStorage.getItem('userWallet'));
        if (userWellet.length > 0) {
            this.showLoader = false;
            this.dataLoaded = true;
            userWellet.forEach(userWall => {
                if (userWall.isDemo === 0) {
                    walletOrder.push(userWall);
                    this.accountDetailWS.primary = this._utils.filteredObj(
                        walletOrder,
                        'isPrimary',
                        true
                    );
                    this.accountDetailWS.primary = this._utils.filteredObj(
                        this.accountDetailWS.primary,
                        'isEnabled',
                        true
                    );
                    this.accountDetailWS.secondary = this._utils.filteredObj(
                        walletOrder,
                        'isPrimary',
                        false
                    );
                    /*this.accountDetailWS.secondary = this._utils.filteredObj(
                        this.accountDetailWS.secondary,
                        'isEnabled',
                        true
                    );*/
                    if (!this.isWalletInit) {
                        this.accountDetails.primary = this._utils.filteredObj(
                            walletOrder,
                            'isPrimary',
                            true
                        );
                        this.accountDetails.primary = this._utils.filteredObj(
                            this.accountDetails.primary,
                            'isEnabled',
                            true
                        );

                        this.accountDetails.secondary = this._utils.filteredObj(
                            walletOrder,
                            'isPrimary',
                            false
                        );
                        /*this.accountDetails.secondary = this._utils.filteredObj(
                            this.accountDetails.secondary,
                            'isEnabled',
                            true
                        );*/
                        this.isWalletPrimaryProperty = false;
                        this.isWalletSecondaryProperty = false;
                    }

                    // if (this.accountDetailWS.primary) {
                    //   this.accountDetailWS.primary.forEach(data => {
                    //     this.primaryAvailable = (data.totalAmount) - (data.reserveAmount)
                    //   })
                    // }
                    // if (this.accountDetailWS.secondary) {
                    //   this.accountDetailWS.secondary.forEach(data => {
                    //     this.secondaryAvailable = (data.totalAmount) - (data.reserveAmount)
                    //   });
                    // }

                    // if (this.accountDetails.primary) {
                    //   this.accountDetailWS.primary.forEach(data => {

                    //     this.primaryAvailable = (data.totalAmount) - (data.reserveAmount)
                    //   })
                    // }
                    // if (this.accountDetails.secondary ) {
                    //   this.accountDetailWS.secondary.forEach(data => {
                    //     this.secondaryAvailable = (data.totalAmount) - (data.reserveAmount)
                    //   })
                    // }
                }
            });
            this.addProperties();
        }

    }

    getUserAccountDetails() {

        this.sub = setInterval(() => {
            let data = {
                idUser: this.userLoginData.idUser,
                username: this.userLoginData.username
            };
            this._accDetail.userDetails(data).subscribe((response: any) => {
                let userwalletData = [];
                if (response.userWallet.length > 0) {
                    this.showLoader = false;
                    this.dataLoaded = true;
                    response.userWallet.forEach((userWall, index) => {
                        if (userWall.isDemo === 0) {
                            userwalletData.push(userWall);
                        }
                    });
                    this.accountDetailWS.primary = this._utils.filteredObj(
                        userwalletData,
                        'isPrimary',
                        true
                    );
                    this.accountDetailWS.primary = this._utils.filteredObj(
                        this.accountDetailWS.primary,
                        'isEnabled',
                        true
                    );

                    this.accountDetailWS.secondary = this._utils.filteredObj(
                        userwalletData,
                        'isPrimary',
                        false
                    );
                    /*this.accountDetailWS.secondary = this._utils.filteredObj(
                        this.accountDetailWS.secondary,
                        'isEnabled',
                        true
                    );*/
                    if (!this.isWalletInit || (this.primaryAvailable !== this.accountDetailWS.primary.length)) {
                        this.accountDetails.primary = this._utils.filteredObj(
                            userwalletData,
                            'isPrimary',
                            true
                        );
                        this.accountDetails.primary = this._utils.filteredObj(
                            this.accountDetails.primary,
                            'isEnabled',
                            true
                        );
                        this.isWalletPrimaryProperty = false;
                        localStorage.removeItem('userWalletValue');
                        localStorage.setItem('userWalletValue', JSON.stringify(response.userWallet));
                    }
                    if (!this.isWalletInit || (this.secondaryAvailable !== this.accountDetailWS.secondary.length)) {
                        this.accountDetails.secondary = this._utils.filteredObj(
                            userwalletData,
                            'isPrimary',
                            false
                        );
                        /*this.accountDetails.secondary = this._utils.filteredObj(
                            this.accountDetails.secondary,
                            'isEnabled',
                            true
                        );*/
                        this.isWalletSecondaryProperty = false;
                        localStorage.removeItem('userWalletValue');
                        localStorage.setItem('userWalletValue', JSON.stringify(response.userWallet));
                    }
                    // if (this.accountDetailWS.primary) {
                    //   this.accountDetailWS.primary.forEach(data => {
                    //     this.primaryAvailable = +(data.totalAmount) - +(data.reserveAmount);

                    //   })
                    // }
                    // if (this.accountDetailWS.secondary) {
                    //   this.accountDetailWS.secondary.forEach(data => {
                    //     this.secondaryAvailable = +(data.totalAmount) - +(data.reserveAmount)
                    //   });
                    // }

                    // if (this.accountDetails.primary) {
                    //   this.accountDetailWS.primary.forEach(data => {

                    //     this.primaryAvailable = (data.totalAmount) - (data.reserveAmount)
                    //   })
                    // }
                    // if (this.accountDetails.secondary ) {
                    //   this.accountDetailWS.secondary.forEach(data => {
                    //     this.secondaryAvailable = (data.totalAmount) - (data.reserveAmount)
                    //   })

                    // }
                    // this.count = response.userWallet.length;
                    this.primaryAvailable = this.accountDetailWS.primary.length;
                    this.secondaryAvailable = this.accountDetailWS.secondary.length;
                    this.isWalletInit = true;
                    this.addProperties();
                } else {
                    this.showLoader = false;
                    this.dataLoaded = true;
                }
            });
        }, 1000);


        // this.userAccSubs = Observable.interval(walletAPIInterval)
        //   .flatMap(() => this._accDetail.getAccountDetailsSch())
        //   .subscribe((data:any) => {
        //     let resSuss = data.data;
        //     let resErr = data.error;
        //     if(resSuss != '') {
        //       this.showLoader = false;
        //       this.dataLoaded = true;
        //       this.accountDetails.primary = this._utils.filteredObj(
        //         resSuss,
        //         "isPrimary",
        //         true
        //       );
        //       this.accountDetails.secondary = this._utils.filteredObj(
        //         resSuss,
        //         "isPrimary",
        //         false
        //       );
        //       this.addProperties();
        //     }
        //     if(resErr != '') {
        //       this.dataLoaded = true;
        //       this.showLoader = false;
        //     }
        //   });


        // this.socketService.userWallet.subscribe(data => {
        //   console.log(data);

        //   if (data.length > 0) {
        //     this.showLoader = false;
        //     this.dataLoaded = true;
        //     this.accountDetails.primary = this._utils.filteredObj(
        //       data,
        //       "isPrimary",
        //       true
        //     );
        //     this.accountDetails.secondary = this._utils.filteredObj(
        //       data,
        //       "isPrimary",
        //       false
        //     );
        //     if (this.accountDetails.primary) {
        //       this.accountDetails.primary.forEach(data => {

        //         this.primaryAvailable = (data.totalAmount) - (data.reserveAmount)
        //       })
        //     }
        //     if (this.accountDetails.secondary) {
        //       this.accountDetails.secondary.forEach(data => {
        //         this.secondaryAvailable = (data.totalAmount) - (data.reserveAmount)
        //       })

        //     }
        //     this.addProperties();
        //   }
        // });


        //  this.socketService.userWallet.subscribe(data => {
        //   if (data.length > 0) {
        //     this.showLoader = false;
        //     this.dataLoaded = true;
        //     this.accountDetails.primary = this._utils.filteredObj(
        //       data,
        //       "isPrimary",
        //       true
        //     );
        //     this.accountDetails.secondary = this._utils.filteredObj(
        //       data,
        //       "isPrimary",
        //       false
        //     );
        //     if (this.accountDetails.primary) {
        //       this.accountDetails.primary.forEach(data => {

        //         this.primaryAvailable = (data.totalAmount) - (data.reserveAmount)
        //       })
        //     }
        //     if (this.accountDetails.secondary) {
        //       this.accountDetails.secondary.forEach(data => {
        //         this.secondaryAvailable = (data.totalAmount) - (data.reserveAmount)
        //       })
        //     }


        //     this.addProperties();
        //   }
        // })

        // this._accDetail.getAccountDetails();

        // this.actDetailSubs = this._accDetail.accountDetails.subscribe(data => {
        //   if (data != "error") {
        //     if (data.length > 0) {
        //       this.showLoader = false;
        //       this.dataLoaded = true;
        //       this.accountDetails.primary = this._utils.filteredObj(
        //         data,
        //         "isPrimary",
        //         true
        //       );
        //       this.accountDetails.secondary = this._utils.filteredObj(
        //         data,
        //         "isPrimary",
        //         false
        //       );
        //       this.addProperties();
        //     }
        //   } else if (data == "error") {

        //   }
        // });
    }

    addProperties() {
        if (!this.isWalletPrimaryProperty) {
            if (this.accountDetails.primary.length > 0) {
                this.accountDetails.primary.forEach(ele => {
                    ele.depositSec = false;
                    ele.withdrawSec = false;
                    ele.nemtransferSec = false;
                    ele.hybtransferSec = false;
                });
            }
            this.isWalletPrimaryProperty = true;
        }
        if (!this.isWalletSecondaryProperty) {
            if (this.accountDetails.secondary.length > 0) {
                this.accountDetails.secondary.forEach(ele => {
                    ele.depositSec = false;
                    ele.withdrawSec = false;
                    ele.nemtransferSec = false;
                    ele.hybtransferSec = false;
                });
            }
            this.isWalletSecondaryProperty = true;
        }
    }

    toggleSecondaryTbl(data, ind, section) {
        if (section == 'deposit') {
            this.accountDetails.secondary[ind].depositSec = !this.accountDetails
                .secondary[ind].depositSec;
            this.accountDetails.secondary[ind].withdrawSec = false;
        } else {
            this.accountDetails.secondary[ind].depositSec = false;
            this.accountDetails.secondary[ind].withdrawSec = !this.accountDetails
                .secondary[ind].withdrawSec;
        }
        this.accountDetails.secondary[ind].nemtransferSec = false;
        this.accountDetails.secondary[ind].hybtransferSec = false;
    }

    togglePrimTbl(data, ind, section) {
        if (section == 'deposit') {
            this.accountDetails.primary[ind].depositSec = !this.accountDetails
                .primary[ind].depositSec;
            this.accountDetails.primary[ind].withdrawSec = false;
        } else {
            this.accountDetails.primary[ind].depositSec = false;
            this.accountDetails.primary[ind].withdrawSec = !this.accountDetails
                .primary[ind].withdrawSec;
        }
        this.accountDetails.primary[ind].nemtransferSec = false;
        this.accountDetails.primary[ind].hybtransferSec = false;
    }

    toggleTransferTbl(data, ind, section) {
        this.accountDetails[data][ind][section] = !this.accountDetails[data][ind][
            section
            ];
        this.accountDetails[data].forEach((ele, i) => {
            this.accountDetails[data][i].withdrawSec = false;
            this.accountDetails[data][i].depositSec = false;
        });
    }

    userDetails: any;

    getUserDetails() {
        this.userDetails = this._datum.getUserDetails();
        this.userLoginData = this._datum.getUserLoginData();
    }

    checkWithdraw(data, ind, section) {
        if (this.userDetails.userLevel == 'New') {
            this.alertModalBox();
        } else {
            this.togglePrimTbl(data, ind, section);
        }
    }

    checkWithdrawSec(data, ind, section) {
        if (this.userDetails.userLevel == 'New') {
            this.alertModalBox();
        } else {
            this.toggleSecondaryTbl(data, ind, section);
        }
    }

    alertModalBox() {
        let message = 'Please Complete Your Bronze Verification Level';
        const dialogRef = this._dialog.open(ModalBoxComponent, {
            width: '450px',
            data: {
                message: message,
                buttonText: 'OK'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    /* Table Sorting */
    sortSecondaryData(sort: Sort) {
        const data = this.accountDetails.secondary.slice();
        if (!sort.active || sort.direction === '') {
            this.accountDetails.secondary = data;
            return;
        }
        this.accountDetails.secondary = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'symbol':
                    return compare(a.symbol, b.symbol, isAsc);
                case 'assetName':
                    return compare(a.assetName, b.assetName, isAsc);
                case 'companyName':
                    return compare(a.companyName, b.companyName, isAsc);
                case 'totalAmount':
                    return compare(a.totalAmount, b.totalAmount, isAsc);
                case 'nemAmount':
                    return compare(a.nemAmount, b.nemAmount, isAsc);
                case 'availableAmount':
                    return compare(a.availableAmount, b.availableAmount, isAsc);
                case 'reserveAmount':
                    return compare(a.reserveAmount, b.reserveAmount, isAsc);
                default:
                    return 0;
            }
        });
    }

    sortPrimaryData(sort: Sort) {
        const data = this.accountDetails.primary.slice();
        if (!sort.active || sort.direction === '') {
            this.accountDetails.primary = data;
            return;
        }
        this.accountDetails.primary = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'symbol':
                    return compare(a.symbol, b.symbol, isAsc);
                case 'assetName':
                    return compare(a.assetName, b.assetName, isAsc);
                case 'totalAmount':
                    return compare(a.totalAmount, b.totalAmount, isAsc);
                case 'nemAmount':
                    return compare(a.nemAmount, b.nemAmount, isAsc);
                case 'availableAmount':
                    return compare(a.availableAmount, b.availableAmount, isAsc);
                case 'reserveAmount':
                    return compare(a.reserveAmount, b.reserveAmount, isAsc);
                default:
                    return 0;
            }
        });
    }


    getTransferStatus(evt) {
        if (evt == 'success') {
            this.getUserAccountDetails();
        }
    }


    ngOnDestroy() {
        clearInterval(this.sub);

        // this.sub.unsubscribe();
        if (typeof this.actDetailSubs != 'undefined') {
            this.actDetailSubs.unsubscribe();
        }
        if (typeof this.userAccSubs != 'undefined') {
            this.userAccSubs.unsubscribe();
        }
    }
}

function compare(a: any, b: any, isAsc: boolean) {
    let item1 = a;
    let item2 = b;
    if (!isNaN(a)) {
        item1 = parseFloat(a);
    }
    if (!isNaN(b)) {
        item2 = parseFloat(b);
    }
    return (item1 < item2 ? -1 : 1) * (isAsc ? 1 : -1);
}

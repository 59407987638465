import { Injectable } from '@angular/core';
import { ApirequestService } from '../apirequest/apirequest.service';
import { ApiUrlService } from '../api-url/api-url.service';
import { SessionOutService } from '../sessionOut/session-out.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { DatumService } from '../datum/datum.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {

  constructor(private _req:ApirequestService,
              private _so: SessionOutService,
              private _url:ApiUrlService,
              private _datum: DatumService) { this.getUserDetails();}
              userData: any = '';
  iboListsRes:Subject<any> = new BehaviorSubject(null);
  iboListsPageRes:Subject<any> = new BehaviorSubject({ data: [], error: false });

  getUserDetails() {
    this.userData = this._datum.getUserLoginData();
    if (Object.keys(this.userData).length === 0) { this.userData = ''; }
  }

  getIBOList() {
    let datum = { data: [], error: false };
    let dataP = {};
      this._req.fetchApiData(this._url.iboList + '?idUser=' + this.userData.idUser, {dataP}, true, 'get').subscribe(
          (data: any) => {
              const resSucc = data.data;
              const resErr = data.error;
              if (resSucc !== '') {
                  datum.data = resSucc;
              }
              if (resErr !== '') {
                  datum.error = resErr;
              }
              this.iboListsRes.next(datum);
              this.iboListsPageRes.next(datum);
          },
          (error) => {
          },
          () => {
          }
      );
  }
}

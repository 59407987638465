import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'batp-initial-deposit-modal',
  templateUrl: './initial-deposit-modal.component.html',
  styleUrls: ['./initial-deposit-modal.component.css']
})
export class InitialDepositModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any) { }


  toggleSection:any = {
    paymentSec: true,
    bankTransfer: false,
    dimCurrency: false
  }
  navToPayment(sec) {
    Object.keys(this.toggleSection).forEach(ele => {
      this.toggleSection[ele] = (ele == sec);
    })
  }

  
  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as rootState from '../../app.reducer';

@Injectable()
export class DatumService {

  constructor( private store:Store<rootState.State> ) { }

  getUserDetails() {
    let userDetails:any = {};
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        userDetails = data.userDetail;
      }
    );
    return userDetails;
  }

  getUserLoginData() {
    let userData:any = {};
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        userData = data.userData;
      }
    );
    return userData;
  }

  getCompanyDetails() {
    let companyDetail:any = {};
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        companyDetail = data.companyDetail;
      }
    );
    return companyDetail;
  }


}

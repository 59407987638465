import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {MarketsService} from 'src/app/services/markets/markets.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
import {AccountDetailsService} from 'src/app/services/accountDetails/account-details.service';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ApirequestService} from 'src/app/services/apirequest/apirequest.service';
import {SnackbarService} from 'src/app/services/snackbar/snackbar.service';
import {SessionOutService} from 'src/app/services/sessionOut/session-out.service';
import {LoginService} from 'src/app/services/login/login.service';
import {ApiUrlService} from 'src/app/services/api-url/api-url.service';
import {StorageService} from 'src/app/services/localstorage/storage.service';
import {UtilityService} from 'src/app/services/utilities/utility.service';
import {validatePrice, validNumber, validNumberWithDecimal} from 'src/app/shared/form-validators';
import {ChangeDetectorRef} from '@angular/core';
import {DatumService} from 'src/app/services/datum/datum.service';

@Component({
    selector: 'batp-market-info-novice',
    templateUrl: './market-info-novice.component.html',
    styleUrls: ['./market-info-novice.component.css']
})
export class MarketInfoNoviceComponent implements OnInit {

    @ViewChild('userPrimaryCurrency') userPrimaryCurrency: TemplateRef<any>;
    @ViewChild('userSellPrimCurrency') userSellPrimCurrency: TemplateRef<any>;
    @ViewChild('userSecondaryShares') userSecondaryShares: TemplateRef<any>;
    @ViewChild('userCompanyList') userCompanyList: TemplateRef<any>;
    @ViewChild('userCurrencyList') userCurrencyList: TemplateRef<any>;

    @ViewChild('userWalletInfo') userWalletInfo: TemplateRef<any>;
    @ViewChild('swapSecOne') swapSecOne: TemplateRef<any>;
    @ViewChild('swapSecTwo') swapSecTwo: TemplateRef<any>;

    showLoader: boolean = true;
    leftTemplate: TemplateRef<any>;
    rightTemplate: TemplateRef<any>;
    stockInfo: any = {};
    tradingBuyForm: FormGroup;
    tradingSellForm: FormGroup;
    sectionLoader: boolean = true;
    paramID: any;
    userBuyStock: Object = {};

    actDetailSubs: any;
    primaryData: any = [];
    secondaryData: any = [];
    userCurrency: any = {};
    userSellCurrency: any = {};
    userSellStock: any = {};
    userSellCurrencyBS: any = [];
    userLoginData;
    userWalletdata: any = [];

    constructor(private _market: MarketsService,
                private _accDetail: AccountDetailsService,
                private _fb: FormBuilder,
                private _utils: UtilityService,
                private _router: Router,
                private _matBS: MatBottomSheet,
                private _req: ApirequestService,
                private _ls: StorageService,
                private _lo: LoginService,
                private _urls: ApiUrlService,
                private _sb: SnackbarService,
                private _so: SessionOutService,
                private _ac: ActivatedRoute,
                private _datum: DatumService) {
    }


    ngOnInit() {
        this.userLoginData = this._datum.getUserLoginData();
        this._ac.params.subscribe(
            data => {
                this.paramID = data.id;
                this.tradingBuyFormInit();
                this.tradingSellFormInit();
                this.getPreferredTheme();
                this.getUserWallet();
                this.getStockLists();
                this.getStockInfo();
                // this.getUserAccountDetails();
            }
        );
    }

    getUserWallet(): void {
        const data = {
            idUser: this.userLoginData.idUser
        };
        this._accDetail.userDetails(data).subscribe((response: any) => {
            if (response.userWallet.length > 0) {
                this.userWalletdata = response.userWallet;
                localStorage.removeItem('userWalletValue');
                localStorage.setItem('userWalletValue', JSON.stringify(this.userWalletdata));
            }
        });
    }

    getStockInfo() {
        this._market.getStockInfomation(this.paramID);
        this._market.stockInformation.subscribe(data => {
            if (data) {
                if (data.status) {
                    this.stockInfo = data.result;
                    this.userSellStock = data.result;
                    //this.getUserAccountDetails();
                    //this.sectionLoader = false;
                } else {
                    this.stockInfo = {};
                }
            }
        });
    }

    tradingBuyFormInit() {
        this.tradingBuyForm = this._fb.group({
            'availableAmount': [''],
            'stockSize': ['', [Validators.required, validNumberWithDecimal]],
            'estimatedTotal': ['0']
        });
    }

    tradingSellFormInit() {
        this.tradingSellForm = this._fb.group({
            'availableStock': [''],
            'stockAmount': ['', [Validators.required, validNumberWithDecimal]],
            'estimatedTotal': ['0']
        });
    }

    stockList: any = [];
    stockListSubs: any;
    stockArraList = [];
    buyStockName: any;
    buyCompanyName: any;
    sellStockName: any;
    sellCompanyName: any;

    getStockLists() {
        this._market.getStockList();
        this.stockListSubs = this._market.stockList.subscribe(
            (data: any) => {
                if (data != null) {
                    if (data != 'error') {
                        this.stockList = data;
                        let stockIdCompany = [];
                        let stockidCompanyObj = {};
                        let stockObj = {};

                        this.stockList.forEach(element => {
                            stockidCompanyObj = {};
                            if (!(element.targetCurr in stockObj)) {
                                stockObj[element.targetCurr] = [];
                            }
                            stockObj[element.targetCurr][element.idCompanyStock] = element;
                            // stockIdCompany[element.targetCurr] = stockidCompanyObj;
                            //stockIdCompany.push(stockidCompanyObj);
                            //stockObj[element.targetCurr].push(stockidCompanyObj);
                        });
                        this.stockArraList.push(stockObj);
                        this.getUserAccountDetails();
                        this.sectionLoader = false;
                    }
                }
            }
        );
    }

    getAssetsCurrencies(assets) {
        let currencies = [];

        for (const item of assets) {
            if (!currencies.includes(item['targetCurr'])) {
                currencies.push(item['targetCurr']);
            }
        }

        return currencies;
    }

    getUserAccountDetails() {
        this.sectionLoader = true;
        let target;
        let pairCurrencies = [];
        const userWalletData = JSON.parse(localStorage.getItem('userWalletValue'));
        if (userWalletData !== null) {
            this.secondaryData = this._utils.filteredObj(userWalletData, 'isPrimary', false);
            this.secondaryData = this._utils.filteredObj(this.secondaryData, 'isDemo', false);
            this.secondaryData = this._utils.filteredObj(this.secondaryData, 'isEnabled', true);
            this.secondaryData = this._utils.filteredObj(this.secondaryData, 'isIbo', false);
            pairCurrencies = this.getAssetsCurrencies(this.stockList);
            this.primaryData = this._utils.filteredObj(userWalletData, 'isPrimary', true);
            this.primaryData = this._utils.filteredObj(this.primaryData, 'isDemo', false);
            this.primaryData = this._utils.filteredObj(this.primaryData, 'isEnabled', true);
            this.primaryData = this._utils.filteredObjOnArray(this.primaryData, pairCurrencies, 'targetCurr', true);
            this.userSellCurrencyBS = this._utils.filteredObj(userWalletData, 'isPrimary', true);
            this.userSellCurrencyBS = this._utils.filteredObj(this.userSellCurrencyBS, 'isDemo', false);
            // let target = this.primaryData[0].assetName.split('-');
            if (this.primaryData.length > 0) {
                const selectedComp = this.getSelectedCompany(this.primaryData[0].symbol, this.primaryData);
                this.getCurrencyCompany(this.primaryData[0].symbol);
                this.setUserDetails(selectedComp[0]);
            }
            // target = target[1].split('.');
            /*userWalletData.forEach(ele => {
              target = ele.symbol.split('-');
            });*/
            if (this.secondaryData.length > 0) {
                const selectedCompCurr = this.secondaryData[0].symbol.split('-');
                this.getSellCurrencyCompany(selectedCompCurr[1]);
                this.getStockDetails(this.secondaryData);
                this.secondaryWithCompanyName();
            }
            this.sectionLoader = false;
        }
        // this._req.fetchApiData(this._urls.userAccountDetailsUrl, {}, false).subscribe(
        //   (data: any) => {
        //     let resSucc = data.data;
        //     let resErr = data.error;

        //   }
        // )
    }

    secondaryWithCompanyName() {
        for (let i = 0; i < this.secondaryData.length; i++) {
            this.secondaryData[i]['companyName'] = this.stockArraList[0][this.secondaryData[i].targetCurr][this.secondaryData[i].idCompanyStock].companyName;
        }
    }

    stockDetails: any = [];

    getStockDetails(secondary) {

        let stock = this._utils.filteredObj(secondary, 'idCompanyStock', secondary[0].idCompanyStock);
        this.stockDetails = stock;
        this.setStockDetails(stock);
    }

    setStockDetails(stock) {
        this.userSellStock = (stock.length > 0) ? stock[0] : this.userSellStock;
        if (stock.length != 0) {
            //let availStock = Math.round( stock[0].availableAmount );
            this.sellCompanyName = this.stockArraList[0][stock[0].targetCurr][stock[0].idCompanyStock].companyName;
            this.sellStockName = this.stockArraList[0][stock[0].targetCurr][stock[0].idCompanyStock].stockName;
            this.sellIdCompanyStock = stock[0].idCompanyStock;
            this.sellIdCompany = this.stockArraList[0][stock[0].targetCurr][stock[0].idCompanyStock].idCompany;

            let availStock = stock[0].availableAmount;
            this.tradingSellForm.controls['availableStock'].setValue(availStock);
        } else {
            this.tradingSellForm.controls['availableStock'].setValue('0');
        }
    }

    buyIdCompanyStock: any;
    buyIdCompany: any;
    sellIdCompanyStock: any;
    sellIdCompany: any;

    setUserDetails(prim = null) {
        if (prim) {
            // this.userCurrency.assetName = prim.assetName;
            // this.userSellCurrency.assetName = prim.assetName;
            this.setUserPrimary(prim);
            this.setUserSecondary(prim, 'availableAmount');
            /*let form = (inputType === 'availableAmount') ? this.tradingBuyForm : this.tradingSellForm;
            form.controls[inputType].setValue(prim.availableAmount);*/
        }
        this.sectionLoader = false;
    }

    setUserPrimary(prim = null, inputType = 'availableAmount') {
        this.userCurrency = prim;
        const form = this.tradingBuyForm;
        form.controls[inputType].setValue(prim.availableAmount);
        this.buyIdCompanyStock = this.selectedCompList[0].idCompanyStock;
        this.buyIdCompany = this.selectedCompList[0].idCompany;
        this.buyStockName = this.stockArraList[0][this.userCurrency.targetCurr][this.selectedCompList[0].idCompanyStock].stockName;
        this.buyCompanyName = this.stockArraList[0][this.userCurrency.targetCurr][this.selectedCompList[0].idCompanyStock].companyName;
    }

    setUserSecondary(prim = null, inputType = 'stockAmount') {
        this.userSellCurrency = prim;
        if (inputType === 'stockAmount') {
            const form = this.tradingSellForm;
            form.controls[inputType].setValue(prim.availableAmount);
        }

        if (this.secondaryData.length > 0) {
            this.sellIdCompanyStock = this.secondaryData[0].idCompanyStock;
            this.sellIdCompany = this.stockArraList[0][this.secondaryData[0].targetCurr][this.secondaryData[0].idCompanyStock].idCompany;
            this.sellStockName = this.stockArraList[0][this.secondaryData[0].targetCurr][this.secondaryData[0].idCompanyStock].stockName;
            this.sellCompanyName = this.stockArraList[0][this.secondaryData[0].targetCurr][this.secondaryData[0].idCompanyStock].companyName;
        }
    }

    openBSheet(type) {
        switch (type) {
            case 'currency':
                this._matBS.open(this.userPrimaryCurrency);
                break;
            case 'company':
                this._matBS.open(this.userCompanyList);
                break;
            case 'stock':
                this._matBS.open(this.userSecondaryShares);
                break;
            case 'sellCurrency':
                this._matBS.open(this.userSellPrimCurrency);
                break;
            default:
                break;
        }
    }


    selectUserCurrency(evt, primary) {
        // let target = primary.symbol.split('.');
        this.getCurrencyCompany(primary.symbol);
        this.setUserPrimary(primary);
        // this.setUserDetails(primary, 'availableAmount');
        this.tradingBuyForm.controls['stockSize'].setValue('');
        this.tradingBuyForm.controls['estimatedTotal'].setValue(0);
        this._matBS.dismiss();
        // if (this.selectedCompList.length > 0) this._router.navigate(['market-info-novice', this.selectedCompList[0].idCompanyStock])
    }

    selectUserShares(evt, secondary) {
        // let stock = [secondary];
        // let target = secondary.symbol.split('-');
        // this.getSellCurrencyCompany(target[1]);
        // this.setStockDetails(stock);
        // this.tradingSellForm.controls['stockAmount'].setValue('');
        // this.tradingSellForm.controls['estimatedTotal'].setValue(0);
        // this._matBS.dismiss();
        let stock = [secondary];
        let target = secondary.symbol.split('-');
        this.stockDetails = stock;
        this.getSellCurrencyCompany(target[1]);
        this.setStockDetails(stock);
        this.tradingSellForm.controls['stockAmount'].setValue('');
        this.tradingSellForm.controls['estimatedTotal'].setValue(0);
        this._matBS.dismiss();
        // this._router.navigate(['market-info-novice', secondary.idCompanyStock])
    }

    selectCompany(evt, company) {
        // this._router.navigate(['market-info-novice', company.idCompanyStock])
        this.buyIdCompanyStock = company.idCompanyStock;
        this.buyIdCompany = company.idCompany;
        this.buyStockName = this.stockArraList[0][this.userCurrency.targetCurr][this.buyIdCompanyStock].stockName;
        this.buyCompanyName = this.stockArraList[0][this.userCurrency.targetCurr][this.buyIdCompanyStock].companyName;
        this.tradingBuyForm.controls['stockSize'].setValue('');
        this.tradingBuyForm.controls['estimatedTotal'].setValue(0);
        this._matBS.dismiss();
    }

    selectSellPrimCurrency(evt, primary) {
        this.userSellCurrency = primary;
        this.setUserSecondary(primary);
        // this.setUserDetails(primary, 'stockAmount');
        this.tradingSellForm.controls['stockAmount'].setValue('');
        this.tradingSellForm.controls['estimatedTotal'].setValue(0);
        this._matBS.dismiss();
    }

    selectedCompList = [];

    getCurrencyCompany(target) {
        let target1 = target.split('.');
        this.selectedCompList = this.stockList.filter(ele => {
            return ele.targetCurr.includes(target1[1]);
        });
    }

    getSellCurrencyCompany(target) {
        this.userSellCurrencyBS = this.primaryData.filter(ele => {
            return ele.symbol.includes(target);
        });
    }


    getSelectedCompany(target, arr) {
        return arr.filter(ele => {
            return ele.symbol.includes(target);
        });
    }

    buyLoader: boolean = false;

    buyStock() {
        if (this.tradingBuyForm.valid) {
            this.buyLoader = true;
            let formVal = this.tradingBuyForm.value;
            let data = {
                type: 'market',
                stop: 0,
                bidPrice: '',
                bidSize: formVal.stockSize,
                idCompanyStock: this.buyIdCompanyStock,
                idCompany: this.buyIdCompany
            };

            this._req.fetchApiData(this._urls.buyOrderUrl, data, false).subscribe(
                (data: any) => {
                    let resSucc = data.data;
                    let resErr = data.error;

                    if (resSucc != '') {
                        this.buyLoader = false;
                        this._sb.openSnackBar(
                            'Your Order Has Been Placed Successfully',
                            3000,
                            'batp_success_snackbar',
                            'center',
                            'top'
                        );
                        this.getStockInfo();
                        // this.getUserAccountDetails();
                        let availableAmount = formVal.availableAmount;
                        availableAmount -= formVal.estimatedTotal;
                        this.tradingBuyForm.controls['stockSize'].setValue('');
                        this.tradingBuyForm.controls['estimatedTotal'].setValue(0);
                        this.tradingBuyForm.controls['availableAmount'].setValue(availableAmount.toFixed(6));
                    }
                    if (resErr != '') {
                        this._sb.openSnackBar(
                            resErr['Error Description'],
                            3000,
                            'batp_error_snackbar',
                            'center',
                            'top'
                        );
                    }
                },
                error => {
                },
                () => {
                    this.buyLoader = false;
                }
            );
        }
    }

    sellLoader: boolean = false;

    sellStock() {
        if (this.tradingSellForm.valid) {
            this.sellLoader = true;
            let formVal = this.tradingSellForm.value;
            let data = {
                type: 'market',
                stop: 0,
                askPrice: '',
                askSize: formVal.stockAmount,
                idCompanyStock: this.sellIdCompanyStock,
                idCompany: this.sellIdCompany
            };

            this._req.fetchApiData(this._urls.sellOrderUrl, data, false).subscribe(
                (data: any) => {
                    let resSucc = data.data;
                    let resErr = data.error;

                    if (resSucc != '') {
                        this.sellLoader = false;
                        this._sb.openSnackBar(
                            'Your Order Has Been Placed Successfully',
                            3000,
                            'batp_success_snackbar',
                            'center',
                            'top'
                        );
                        this.getStockInfo();
                        // this.getUserAccountDetails();
                        let availableStock = formVal.availableStock;
                        availableStock -= formVal.stockAmount;
                        this.tradingSellForm.controls['stockAmount'].setValue('');
                        this.tradingSellForm.controls['estimatedTotal'].setValue(0);
                        this.tradingSellForm.controls['availableStock'].setValue(availableStock.toFixed(6));
                    }
                    if (resErr != '') {
                        this._sb.openSnackBar(
                            resErr['Error Description'],
                            3000,
                            'batp_error_snackbar',
                            'center',
                            'top'
                        );
                    }
                },
                error => {
                },
                () => {
                    this.sellLoader = false;
                }
            );
        }
    }

    preferredTheme: boolean;

    getPreferredTheme() {
        let preferredTheme: any = this._ls.getLocalItems('preferredTheme');
        this._lo.preferredTheme.subscribe(
            data => {
                if (data != null) {
                    this.preferredTheme = data;
                } else {
                    this.preferredTheme = preferredTheme == 'true' ? true : false;
                }
            }
        );
    }

    // commissionFee:any = 0.1;
    commissionFee: any = 0.25;
    estimatedBuyTotal: any = 0;
    estimatedSellTotal: any = 0;
    commissionFeeBuy: any = 0.000001;
    commissionFeeSell: any = 0.000001;

    getTotal(type) {
        let orderPrice, orderSize, bidTotal, totalCommission;
        if (type == 'buy') {
            orderSize = this.tradingBuyForm.controls['stockSize'].value;
            if (!isNaN(orderSize)) {
                //orderPrice = this.stockInfo.priceCurrent;
                orderPrice = this.stockArraList[0][this.userCurrency.targetCurr][this.buyIdCompanyStock].priceCurrent;
                bidTotal = orderPrice * orderSize;
                this.estimatedBuyTotal = (this.commissionFee / 100) * bidTotal;
                this.estimatedBuyTotal = bidTotal + this.estimatedBuyTotal;
                this.commissionFeeBuy = this.estimatedBuyTotal - bidTotal;
                this.tradingBuyForm.controls['estimatedTotal'].setValue(this.estimatedBuyTotal.toFixed(6));
            }
        } else {
            orderPrice = this.tradingSellForm.controls['stockAmount'].value;
            if (!isNaN(orderPrice)) {
                //orderSize = this.stockInfo.priceCurrent;
                orderSize = this.stockArraList[0][this.stockDetails[0].targetCurr][this.stockDetails[0].idCompanyStock].priceCurrent;
                bidTotal = orderPrice * orderSize;
                this.estimatedSellTotal = (this.commissionFee / 100) * bidTotal;
                this.estimatedSellTotal = bidTotal + this.estimatedSellTotal;
                this.commissionFeeSell = this.estimatedSellTotal - bidTotal;
                this.tradingSellForm.controls['estimatedTotal'].setValue(this.estimatedSellTotal.toFixed(6));
            }
        }
    }


}

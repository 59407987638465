import { Injectable } from '@angular/core';
import { fsKey } from '../../config/batp.config';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";

declare var filestack:any;
@Injectable()
export class FsUploadService {

  constructor() { }

  uploadFiles(file,size) {
    let fsClient = filestack.init(fsKey);
    let fileAccepted = file;
    let maxSize = size;
    let fileOptions = {
      fromSources:["local_file_system"],
      accept: fileAccepted,
      maxFiles:1,
      minFiles:1,
      transformations:{
        crop:true,
        circle:false
      },
      maxSize:maxSize
    };
    return fsClient.pick(fileOptions).then((response)=>{
      return response;
    });

  }


}

import { Component, OnInit } from '@angular/core';
import { HeartBeatService } from '../../../services/heartBeat/heart-beat.service';
import { environment } from '../../../../environments/environment';
import { UtilityService } from 'src/app/services/utilities/utility.service';

@Component({
  selector: 'batp-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  constructor(private _hb:HeartBeatService,private _utils:UtilityService) { }
  showTestNetBar:boolean = environment.showTestNetSections;
  changeOfRoute($e) {

  }
  menuStatus:boolean = false;
  menuToggle() {
    this.menuStatus = !this.menuStatus;
  }

  isMobile:boolean = false;
  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'batp-ib-footer',
  templateUrl: './ib-footer.component.html',
  styleUrls: ['./ib-footer.component.css']
})
export class IbFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export class ErrorMessages {
  constructor() { }

  private emailRequired:string = "Please Enter Your Email";

  private validEmail:string = "Please Enter a Valid Email ID";

  private passwordRequired:string = "Please Enter Your Password";

  private validatePassword:string = "Your Password Must have a UpperCase and a Special Character with length of 8 to 18 characters";

  private confirmPasswordRequired:string = "Please Re-Enter the same Password";

  private confirmPasswordValidate:string = "Entered Password Doesn't match";

  private termsValidate:string = "Please Accept Our Terms & Condition";

  private userFormSubmission:string = "You have Successfully registered your BEX account. Please activate the link you received by email.";

  private firstNameRequired:string = "Please Enter Your First Name";

  private lastNameRequired:string = "Please Enter Your Last Name";

  private firstLastNameRequired:string = "Please Enter Both Your First and Last Name";


  //Change Password
  private changeOldPwdReq:string = "Please Enter Your Old Password";

  private newPwdReq:string = "Please Enter Your New Password";

  //Change Email
  private currentEmailReq:string = "Please Enter Your Current Email Id";

  private currentPwdReq:string = "Please Enter Your Current Password";

  private newEmailReq:string = "Please Enter Your New Email Password";

  //Update Profile
  private countryReq:string = "Please Select your Country";

  private zipCodeReq:string = "Please Enter your Zipcode";

  private cityReq:string = "Please Enter your City";

  private addressReq:string = "Please Enter your Adress";

  private phoneCodeReq:string = "Please Select your Country Code";

  private phoneReq:string = "Please Enter your Phone Number";

  private phoneValid:string = "Please a Valid Phone Number";

  private dobReq:string = "Please Enter your Date Of Birth";

  private dobValid:string = "Your Age must be greater than 18years";

  private zipCodeValid:string = "Please Enter a Valid Zip Code";

  private walletReq:string = "Please Enter Your Wallet Address";

  //Silver Verification
  private idNowRew:string = "Please Enter Your IDNow Number";

  private IdDocNumberReq:string = "Please Enter Selected Document ID Number";

  private docExpiryDate:string = "Please Enter Selected Document Expiry Date";

  private docUploadReq:string = "Please Upload the Selected Document File";

  private photoUploadReq:string = "Please Upload Your Image";

  //Contact Form
  private nameReq:string = "Please Enter Your Name";

  private messageBodyReq:string = "Please Enter Your Message";

  //Ibo Purchase Form
  private shareSizeReq:string = "Please Enter Number Of Shares";

  private validNumber:string = "Please Enter Valid Value of Shares";


  //Two Factor Code
  private twoFactorvalidNumber:string = "Please Enter Valid Code";

  private twoFactorMaxLen:string = "Please Enter Valid Code";

  //Markets
  private priceRequired:string = "Please Enter Your Price";

  private priceValid:string = "Please Enter a Valid Value";

  private sizeRequired:string = "Please Enter Your Size";

  // Get in touch
    private preferredLanguage: string = 'Please select your preferred language';
    private contactBy: string = 'Please select a contact method';
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDatepickerInputEvent, MatDialog } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidAge, validNumber, validPhoneNumber } from 'src/app/shared/form-validators';
import { select, Store } from '@ngrx/store';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';
import { SessionOutService } from 'src/app/services/sessionOut/session-out.service';
import * as rootState from '../../../app.reducer';
import { countries, Countries } from 'src/app/shared/data/countries';
import { ErrorMessages } from 'src/app/shared/error-messages';

@Component({
  selector: 'batp-update-profile-modal',
  templateUrl: './update-profile-modal.component.html',
  styleUrls: ['./update-profile-modal.component.css']
})
export class UpdateProfileModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
  private dialog: MatDialog,
  private _req:ApirequestService,
  private _urls: ApiUrlService,
  private _route:Router,
  private store:Store<rootState.State>,
  private _ls: LoginService,
  private _so:SessionOutService,
  private _fb:FormBuilder) { }


  updateProfStat:any = {
    message: '',
    icon: '',
    messageType: ''
  };
  countryList:Countries[] = countries;
  updateProfForm:FormGroup;
  errorMessages:any = new ErrorMessages();
  updateProfFormInit() {
    this.updateProfForm = this._fb.group({
      gender: ['Male',[Validators.required]],
      firstName: ['',[Validators.required]],
      lastName: ['',[Validators.required]],
      dobDate: ['',[Validators.required,ValidAge]],
      country: ['',[Validators.required]],
      address1: ['',[Validators.required]],
      address2: [''],
      zipCode: ['',[Validators.required,validNumber]],
      city: ['',[Validators.required]],
      countryCode: ['',[Validators.required]],
      mobile: ['',[Validators.required, validPhoneNumber]],
    });
  }

  dobDate:any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.dobDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.dobDate = new Date(this.dobDate);
  }

  
  showLoaderProf:boolean = false;
  updateProfFormSubmit() {
    if(this.updateProfForm.valid) {
      this.showLoaderProf = true;
      let data = this.updateProfForm.value;
      data.dateofBirth = this.dobDate;
      this._req.fetchApiData(this._urls.updateUserDetailsUrl,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc!=''){
            this._ls.getUserDetails(); // From Api
            this.getUserDetails(); // From Local Class
            this.setMessage('Your Profile has been Updated Successfully','fal fa-check-circle','success');
            this.dialog.closeAll();
          }
          if( resErr != '') {
            this.setMessage(resErr['Error Description'],'fal fa-check-circle','error');
            this._so.handleSessionOutErr(resErr);
          }
        },
        error => {

        },
        () => {
          this.showLoaderProf = false;
        }
      )
    }
  }

  setMessage(message,icon,messageType,type = 'profile') {
    if(type == 'profile') {
      this.updateProfStat.message = message;
      this.updateProfStat.icon = icon;
      this.updateProfStat.messageType = messageType;
    } 
    setTimeout(() => {
      this.updateProfStat.message = '';
    },5000);
  }
  userDetails:any = {};
  getUserDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        this.userDetails = data.userDetail;
      }
    );
  }
  
  setProfDetails() {
    this.updateProfForm.patchValue({
      gender: this.getValue(this.data.gender,'Male'),
      firstName: this.getValue(this.data.firstName,''),
      lastName: this.getValue(this.data.lastName,''),
      dobDate: this.getValue(this.data.dateofBirth,''),
      country: this.getValue(this.data.country,''),
      address1: this.getValue(this.data.address1,''),
      address2: this.getValue(this.data.address2,''),
      zipCode: this.getValue(this.data.zipCode,''),
      city: this.getValue(this.data.city,''),
      countryCode: this.getValue(this.data.telcode,''),
      mobile: this.getValue(this.data.mobile,'')
    });
  }
  getValue(val,retVal:string = 'N/A') {
    return (val == '' || val == 'N/A') ? retVal : val;
  }

  ngOnInit() {
    this.updateProfFormInit();
    setTimeout(() => {
      this.setProfDetails();
    }, 200); 
  }

}

import { Component, OnInit } from '@angular/core';
import { PageContentService } from '../../../services/pageContent/page-content.service';
import { faqCatId, faqAlias } from '../../../shared/data/content-page-data';

@Component({
  selector: 'batp-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private _ps:PageContentService) { }
  faqContent:any = [];
  faqContentClone:any = [];
  showLoader:boolean = true;
  getFaqCont() {
    this._ps.getContentByCategory(faqCatId,faqAlias);
    this._ps.faqContent
    .subscribe(
      data => {
        if(data) {
          this.faqContent = data;
          this.faqContentClone = data;
          this.showLoader = false;
        }
      },
      error => {

      },
      () => {

      }
    );
  }

  searchFAQ(form) {
    let searchKey = form.search_input;
    if(searchKey != '') {
      let StrippedString = searchKey.replace(/(<([^>]+)>)/ig,"");
      this.faqContentClone = this.faqContent.filter((ele)=>{
        return (ele.title.toLowerCase()).search(StrippedString.toLowerCase()) > -1;
      });
    } else {
      this.faqContentClone = this.faqContent;
    }
  }
  toggleFaq:boolean = false;
  showHideList() {
    this.toggleFaq = !this.toggleFaq;
  }


  ngOnInit() {
    this.getFaqCont();
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validateEmail } from '../../../shared/form-validators';
import { messageBoxIcon, iconSet } from '../../../shared/shared.data';
import { captchaKey, devEnv } from '../../../config/batp.config';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { ErrorMessages } from '../../../shared/error-messages';

@Component({
  selector: 'batp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private _fb:FormBuilder,
              private _req: ApirequestService,
              private _urls:ApiUrlService) { }
  loginMessage:any = '';
  forgotPwdMsg:any = '';
  forgotPwdStatus:boolean = false;
  showLoader:boolean = false;
  messIcons:iconSet = messageBoxIcon;
  captchaKey:string = captchaKey;
  forgotPwdForm:FormGroup;
  errorMessages:any = new ErrorMessages();
  forgotPwdFormInit() {
    this.forgotPwdForm = this._fb.group({
      'email': ['',[Validators.required,validateEmail]],
      'g_recatcha': ['', devEnv ? [] : [Validators.required] ]
    });
  }

  forgotPwd() {
    if(this.forgotPwdForm.valid) {
      this.showLoader = true;
      let formVal = this.forgotPwdForm.value;
      let data = {
        email: formVal.email
      };
      this._req.fetchApiData(this._urls.forgotPasswordUrl,data).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
            this.forgotPwdMsg = 'Password Reset Mail has been sent to your Email Address';
            this.forgotPwdStatus = true;
            this.forgotPwdForm.reset();
          }
          if(resErr != '') {
            this.forgotPwdMsg = resErr['Error Description'];
            this.forgotPwdStatus = false;
          }
        },
        error => {

        },
        () => {
          this.showLoader = false;
        }
      )
    }
  }
  handleSuccess(evt) {

  }
  ngOnInit() {
    this.forgotPwdFormInit();
  }

}

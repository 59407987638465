import { FormGroup,FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit,Output, Input, EventEmitter } from '@angular/core';
import { TooltipModule } from "ngx-tooltip";
import { wizardErrorMessage, wizardMessages } from 'src/app/shared/wizard-error-messages';

export interface Sector {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'batp-wiz-stock-information',
  templateUrl: './wiz-stock-information.component.html',
  styleUrls: ['./wiz-stock-information.component.css']
})
export class WizStockInformationComponent implements OnInit {

  constructor(private _fb:FormBuilder) { }
  companyStockInfoForm:FormGroup;
  stockInfoFormSubmitted:boolean = false;
  stockInfoDetail:any = '';
  marketData:any = [];
  @Input() companyStockInf:any = '';
  @Input() goTo:any = '';
  @Output() stockInfoDatas = new EventEmitter();
  @Output() prevStepper = new EventEmitter();
  errorMessage:wizardMessages = wizardErrorMessage;

  stockInfoFormInit() {
    this.companyStockInfoForm = this._fb.group({
      shareholdingCapital: ['', [Validators.required]],
      paidinCapital: ['', [Validators.required]],
      authorizedShares: ['', [Validators.required]],
      shareValue: ['', [Validators.required]],
      outstandingShares: ['', [Validators.required]],
      shareType: ['', [Validators.required]],
      isin: ['', [Validators.required]],
      ibin: ['', [Validators.required]],
      preferredSymbol: ['', [Validators.required]],
      businessActivities: ['', [Validators.required]],
      marketplace : this._fb.array( [this.createMrktFields(true)] )
    });
  }
  sectors: Sector[] = [
    {value: 'Initial Production', viewValue: 'Initial Production'},
    {value: 'Industrial Sector', viewValue: 'Industrial Sector'},
    {value: 'Service Sector', viewValue: 'Service Sector'},
    {value: 'Information Sector', viewValue: 'Information Sector'}
  ];
  get formData() { return <FormArray>this.companyStockInfoForm.get('marketplace'); }

  createMrktFields(req:boolean = false): FormGroup {
    return this._fb.group({
      marketplace: ['']
    });
  }

  addMarketPlace(): void {
    let marketListss = this.companyStockInfoForm.get('marketplace') as FormArray;
    marketListss.push(this.createMrktFields());
  }
  removeMarketPlace(i) {
    let marketListss = this.companyStockInfoForm.get('marketplace') as FormArray;
    marketListss.removeAt(i);
  }
  stockInfoSubmit() {
    let formArry:any = this.companyStockInfoForm.get('marketplace').value
    formArry.forEach(arr => {
      console.log(arr)
      this.marketData.push(arr.marketplace)
    });
    this.stockInfoFormSubmitted = true;
    this.companyStockInfoForm.value.marketplace = this.marketData;
    if(this.companyStockInfoForm.valid) {
      this.stockInfoDetail = this.companyStockInfoForm.value;
      let exportData:any = {
        formData: this.stockInfoDetail,
        goTo: 3
      }
      this.stockInfoDatas.emit(exportData);
    }
  }
  goToPrevious() {
    this.stockInfoDetail = this.companyStockInfoForm.value;
    let prevExpData:any = {
      formData: this.stockInfoDetail,
      goTo: 1
    }
    this.prevStepper.emit(prevExpData);
  }

  setFormValues() {
    setTimeout(()=>{
      if(typeof this.companyStockInfoForm != undefined) {
        this.companyStockInfoForm.patchValue(this.companyStockInf);
        this.setMarketPlace(this.companyStockInf.marketplace)
      }
    },200);
  }
  setMarketPlace(mktPlace) {
    let mktFields = <FormArray>this.companyStockInfoForm.get('marketplace');
    mktFields.removeAt(0);
    mktPlace.forEach((ele,i) => {
        mktFields.push(this._fb.group({
          marketplace: ele
        }));
    });
  }
  ngOnInit() {
    this.stockInfoFormInit();
  }
  ngOnChanges() {
    if(this.companyStockInf != '') {
      this.setFormValues();
    }
  }
}

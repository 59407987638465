import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utilities/utility.service';

@Component({
  selector: 'batp-issuer-profile',
  templateUrl: './issuer-profile.component.html',
  styleUrls: ['./issuer-profile.component.css']
})
export class IssuerProfileComponent implements OnInit {

  constructor(private _utils:UtilityService) { }
  menuStatus:boolean = false;
  menuToggle() {
    this.menuStatus = !this.menuStatus;
  }

  isMobile:boolean = false;
  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
  }

}

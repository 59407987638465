import { Component, OnInit, Input } from '@angular/core';

import { ApiUtilsService } from '../../../services/apiUtils/api-utils.service';
import { UtilityService } from '../../../services/utilities/utility.service';


declare var $:any;
@Component({
  selector: 'batp-home-ticker',
  templateUrl: './home-ticker.component.html',
  styleUrls: ['./home-ticker.component.css']
})
export class HomeTickerComponent implements OnInit {



  constructor(private _apiUtils:ApiUtilsService,
              private _utils:UtilityService) { }

  iboLists:any = [];
  showLoader:boolean = true;
  getIBOList() {
    this._apiUtils.getIBOList();
    this._apiUtils.iboListsRes.subscribe(
      (data:any) => {
        if(data.data.length > 0) {
          this.iboLists = data.data;
          this.iboLists.sort(this._utils.dynamicSort("idCompany"));
          this.initiateTicker();
          this.showLoader = false;
        }
        if (data.error) {
          this.showLoader = false;
        }
      }
    );
  }

  initiateTicker() {
    setTimeout(()=>{
      $('.ticker_carousel').carouselTicker();
    },100);
  }

  ngOnInit() {
    this.getIBOList();
  }

  getDiscountedPrice(comp) {
    let askPrice = comp.askPrice;
    let discPercent = comp.lockupStock[0].discount;
    let disPrice = ( askPrice / 100 ) * discPercent;
    return disPrice;
  }


  ngOnChanges() {

  }
}

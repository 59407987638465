import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { SessionOutService } from '../../../services/sessionOut/session-out.service';

@Component({
  selector: 'batp-issuer-register-wizard',
  templateUrl: './issuer-register-wizard.component.html',
  styleUrls: ['./issuer-register-wizard.component.css']
})
export class IssuerRegisterWizardComponent implements OnInit {

  constructor(private _ar:ActivatedRoute,
              private _req:ApirequestService,
              private _so:SessionOutService,
              private _url:ApiUrlService) { }
  registrationComp:boolean = false;
  wizardStepper:any = [
    {
      step: 1,
      active: true,
      completed: false,
      stepSection: true
    },
    {
      step: 2,
      active: false,
      completed: false,
      stepSection: false
    },
    {
      step: 3,
      active: false,
      completed: false,
      stepSection: false
    },
    {
      step: 4,
      active: false,
      completed: false,
      stepSection: false
    },
    {
      step: 5,
      active: false,
      completed: false,
      stepSection: false
    },
    {
      step: 6,
      active: false,
      completed: false,
      stepSection: false
    },
    {
      step: 7,
      active: false,
      completed: false,
      stepSection: false
    }
  ];

  importedStep:number;
  companyInformation:any = '';
  companyDescription:any = '';
  stockInformation:any = '';
  managementInformation:any = '';
  financialDocument:any = '';
  iboInformation:any = '';
  companyLogo:any = '';
  formData:any = {};
  issuerDetail:any =[];
  compInfoData:any;
  getImportedData(e) {
    this.importedStep = e.goTo;
    this.formData = Object.assign(this.formData,e.formData);
    if(e.goTo == 1) this.compInfoData = e.formData;
    this.progressStep(this.importedStep);
    this.setFormData(this.importedStep,e.formData);
  }
  getLogoDetails(event) {
    if(event) this.companyLogo = event;
  }

  progressStep(step) {
    this.wizardStepper.forEach(ele => {
      ele.stepSection = false; ele.active = false;
    });
    this.wizardStepper[step].stepSection = true;
    this.wizardStepper[(step ==0 ) ? step : step-1].completed = true;
    this.wizardStepper[step].active = true;
  }

  goToPreviousAct(e) {
    this.progressStep(e.goTo);
    this.setPrevFormData(e.goTo, e.formData);
  }
  setFormData(form,data) {
    switch (form) {
      case 1:
        this.companyInformation = data;
        break;
      case 2:
        this.companyDescription = data;
        break;
      case 3:
        this.stockInformation = data;
        break;
      case 4:
        this.managementInformation = data;
        break;
      case 5:
        this.iboInformation = data;
        break;
      case 6:
        this.financialDocument = data;
        break;
      default:
        break;
    }
  }

  setPrevFormData(form,data) {
    switch (form) {
      case 0:
        this.companyDescription = data;
        break;
      case 1:
        this.stockInformation = data;
        break;
      case 2:
        this.managementInformation = data;
        break;
      case 3:
        this.financialDocument = data;
        break;
      // case 3:
      //   this.financialDocument = data;
      //   break;
      default:
        break;
    }
  }
  getIssuerDetail(id) {
    this._req.fetchApiData(this._url.issuerDetailUrl,{},false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.issuerDetail = resSucc;
        }
        if(resErr != '') {
          this._so.handleSessionOutErr(resErr);
        }
      }
    )
  }

  regStatus:any;
  getStatusData(evt) {
    this.wizardStepper.forEach(ele => {
      ele.stepSection = false; ele.active = false;
    });
    if(evt.status) {
      this.registrationComp = evt.status;
    }
    this.wizardStepper[ evt.goTo ].completed = true;
    this.regStatus = evt;
  }
  gotoNext(sec, prev:boolean = false) {
    this.progressPrevStep(sec,prev)
  }
  progressPrevStep(step, prev:boolean = false) {
    this.wizardStepper.forEach(ele => {
      ele.stepSection = false; ele.active = false;
    });
    this.wizardStepper[step].stepSection = true;
    this.wizardStepper[(step ==0 ) ? step : step-1].completed = prev ? true : false;
    this.wizardStepper[step].active = true;
  }
  ngOnInit() {
    this._ar.params.subscribe(
      data=> {
        if(data.id) this.getIssuerDetail(data.id);
      }
    )
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { businessActivities, bussActivities } from '../../../../shared/data/business-activities';
import { Countries, countries } from '../../../../shared/data/countries';
import { validNumber, validDesiredListing, shareValValidate } from '../../../../shared/form-validators';
import { wizardMessages, wizardErrorMessage } from '../../../../shared/wizard-error-messages';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { MatDatepickerInputEvent } from '@angular/material';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';

@Component({
  selector: 'batp-wiz-company-description',
  templateUrl: './wiz-company-description.component.html',
  styleUrls: ['./wiz-company-description.component.css']
})
export class WizCompanyDescriptionComponent implements OnInit {

  constructor(private _fb:FormBuilder,private _req: ApirequestService,private _urls:ApiUrlService) { }
  @Input() goTo:any = '';
  @Input() companyDesc:any = '';
  @Input() compInfoData:any = '';
  @Output() prevStepper = new EventEmitter();
  @Output() companyDescDatas = new EventEmitter();

  companyDescForm:FormGroup;
  companyDescDetail:any = '';
  businessActivities:bussActivities[] = businessActivities;
  countriesList:Countries[] = countries;
  companyDescFormSubmitted:boolean = false;
  errorMessage:wizardMessages = wizardErrorMessage;
  companyDescFormInit() {
    this.companyDescForm = this._fb.group({
      incorporationCountry: ['',[Validators.required]],
      legalForm:['',[Validators.required]],
      incorporationYear: ['',[Validators.required]],
      incorporationNumber: ['',[Validators.required]],
      companyActivity: ['',[Validators.required]],
      dividentIssueDate: ['', [Validators.required,validDesiredListing]],      
      dimCurrency: ['',[Validators.required]],
      auditor: ['',[Validators.required]],
    //  incorporationCity: ['',[Validators.required]],     
      // authorizedShares: ['',[Validators.required,validNumber]],
      // shareValue: ['',[Validators.required,validNumber,shareValValidate]],
      // outstandingShares: ['',[Validators.required,validNumber]],
      // authorizedShares: [''],
      // shareValue: [''],
      // outstandingShares: [''],      
      // duns: ['',[Validators.required]],
      //duns: [''],     
    //  isin: [''],
      // ibin: ['',[Validators.required]],
      // shareholders: ['',[Validators.required,validNumber]],
      // businessActivities: ['',[Validators.required]]
     // ibin: [''],
    //  shareholders: [''],
    //  businessActivities: [''],
    });
  }

  goToPrevious() {
    // this.prevStepper.emit(0);
    this.companyDescDetail = this.companyDescForm.value;
    let prevExpData:any = {
      formData: this.companyDescDetail,
      goTo: 0
    }
    this.prevStepper.emit(prevExpData);
  }

  setDividentDate(days) {
    let toDay = new Date();
    let dividentDate = toDay.setDate(toDay.getDate() + parseInt(days));
    return new Date(dividentDate);
  }
  incorporationYear:any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.incorporationYear = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.incorporationYear = new Date(this.incorporationYear);
  }
  dividentIssueDate:any;
  issuedatePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.dividentIssueDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.dividentIssueDate = new Date(this.dividentIssueDate);
  }
  companyDescSubmit() {
    if(this.companyDescForm.valid) {
      //this.checkIBINValid();

      this.ibinValid = true;
      this.companyDescDetail = this.companyDescForm.value;
      let exportData:any = {
        formData: this.companyDescDetail,
        goTo: 2
      }
      this.companyDescDatas.emit(exportData);
    }
  }
  ibinValid:boolean = true;
  checkIBINValid() {
    this.companyDescFormSubmitted = true;
    let ibin = this.companyDescForm.value.ibin;
    let companyName = this.compInfoData.companyName;
    let apiUrl = `https://ibin.global/api/verifyCompany/${companyName}/ibin/${ibin}`;
    this._req.fetchApiData(apiUrl,{},true,'get').subscribe(
      (data:any) => {
        //if(data.status == 200 && data.success) {
          this.ibinValid = true;
          this.companyDescDetail = this.companyDescForm.value;
          let exportData:any = {
            formData: this.companyDescDetail,
            goTo: 2
          }
          this.companyDescDatas.emit(exportData);
        //}
      },
      error => {

      },
      () => {
        
      }
    )

    
  }
  setFormValues() {
    setTimeout(()=>{
      if(typeof this.companyDescForm != undefined) {
        this.companyDescForm.patchValue(this.companyDesc);
      }
    },200);
  }
  primarySymbol:any =[];
  getPrimarycurrencyDetails() {
    this._req.fetchApiData(this._urls.primarySymbolUrl,{},false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.primarySymbol = resSucc;
        }

        if(resErr != '') {

        }
      }
    )
  }
  ngOnInit() {
    this.companyDescFormInit();
    this.getPrimarycurrencyDetails();
  }
  ngOnChanges() {
    if(this.companyDesc != '') {
      this.setFormValues();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { checkPassword, validatePassword, validateEmail, validNumber } from '../../../../shared/form-validators';
import { ErrorMessages } from '../../../../shared/error-messages';
import { ApirequestService } from '../../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../../services/api-url/api-url.service';
import { SessionOutService } from '../../../../services/sessionOut/session-out.service';
import { DatumService } from 'src/app/services/datum/datum.service';
import { CryptoService } from '../../../../services/crypto/crypto.service';

@Component({
  selector: 'batp-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {

  constructor(
      private _fb: FormBuilder,
      private _url: ApiUrlService,
      private _datum: DatumService,
      private _so: SessionOutService,
      private _req: ApirequestService,
      private _cryp: CryptoService,
  ) { }

  changePwdForm: FormGroup;
  changeEmailForm: FormGroup;
  twoFactorForm: FormGroup;
  errorMessages:any = new ErrorMessages();
  showLoaderPwd:boolean = false;
  showLoaderEmail:boolean = false;
  changeEmailSubmitted:boolean = false;
  changePwdStat:any = {
    message: '',
    icon: '',
    messageType: ''
  };
  changeMailStat:any = {
    message: '',
    icon: '',
    messageType: ''
  };
  changePwdFormInit() {
    this.changePwdForm = this._fb.group({
      'old_password': ['',[Validators.required]],
      'password': ['',[Validators.required,validatePassword]],
      'confirmPassword': ['',[Validators.required]]
    },{ validator: [checkPassword] });
  }
  changeEmailFormInit() {
    this.changeEmailForm = this._fb.group({
      'current_email_address': ['',[Validators.required,validateEmail]],
      'current_password': ['',[Validators.required]],
      'new_email_address': ['',[Validators.required,validateEmail]]
    });
  }
  twoFactorFormInit() {
    this.twoFactorForm = this._fb.group({
      'two_factor_code': ['',[Validators.required, validNumber, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  changePwdSubmit() {
    if(this.changePwdForm.valid) {
      this.showLoaderPwd = true;
      const formVal = this.changePwdForm.value;
      const data = {
        oldPassword: formVal.old_password,
        newPassword: formVal.password,
      };

      const encData = {
          load: this._cryp.encrypt(JSON.stringify(data))
      };

      this._req.fetchApiData(this._url.changePasswordUrl, encData, false).subscribe(
        (data:any) => {
          let resSucc= data.data;
          let resErr = data.error;
          this.showLoaderPwd = false;
          if(resSucc!='') {
            this.changePwdStat = {
              message: 'Your Password has been Changed Successfully',
              icon: 'fal fa-check-circle',
              messageType: 'success'
            };
            this.changePwdForm.reset();
          }
          if(resErr!='') {
            this.changePwdStat = {
              message: resErr['Error Description'],
              icon: 'fal fa-exclamation-triangle',
              messageType: 'error'
            };
            this._so.handleSessionOutErr(resErr);
          }
        },
        error => {

        },
        () => {

        }
      )
    }
  }

  showTwoFALoader:boolean = false;
  twoFactorData:any = '';
  isTwoFactorEnabled:boolean = false;
  toggleGoogleAuth(enable) {
    let data = {
      enabled: enable
    }
    this.showTwoFALoader = true;
    this._req.fetchApiData(this._url.verify2faUrl,data,false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        this.showTwoFALoader = false;

        if(resSucc != '') {
          if(enable == 0) {
            this.isTwoFactorEnabled = false;
          } else {
            this.twoFactorData = resSucc;
            this.twoFactorFormInit();
          }
        }
        if(resErr != '') {
          this._so.handleSessionOutErr(resErr);
        }
      },
      error => {

      },
      () => {

      }
    );
  }

  twoFactorSubmit() {
    if(this.twoFactorForm.valid) {
      this.showTwoFALoader = true;
      let formVal = this.twoFactorForm.value;
      let data = {
        secret: this.twoFactorData.secretKey,
        code: formVal.two_factor_code
      }
      this._req.fetchApiData(this._url.verifyQRCodeUrl,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          this.showTwoFALoader = false;
          if(resSucc != '') {
            this.isTwoFactorEnabled = true;
          }

          if(resErr != '') {

          }
        }
      )
    }
  }



  changeEmailSubmit() {
    if(this.changeEmailForm.valid) {
      this.showLoaderEmail = true;
      let formVal = this.changeEmailForm.value;
      let data = {
        oldEmail:formVal.current_email_address,
        newEmail: formVal.new_email_address,
        password: formVal.current_password
      };
      this._req.fetchApiData(this._url.changeEmailUrl,data,false).subscribe(
        (data:any) => {
          let resSucc= data.data;
          let resErr = data.error;
          this.showLoaderEmail = false;
          if(resSucc!='') {
            this.changeEmailSubmitted = true;
            // this.changeMailStat = {
            //   message: 'Your Email has been Changed Successfully',
            //   icon: 'far fa-thumbs-up',
            //   messageType: 'success'
            // };
            this.changeEmailForm.reset();
          }
          if(resErr!='') {
            this.changeMailStat = {
              message: resErr['Error Description'],
              icon: 'far fa-thumbs-down',
              messageType: 'error'
            }
            this._so.handleSessionOutErr(resErr);
          }
        },
        error => {

        },
        () => {

        }
      )
    }
  }


  userDetails:any = [];
  getUserData() {
    let userDetail = this._datum.getUserDetails();
    this.isTwoFactorEnabled =  (userDetail.user2fa ==1) ? true : false;
  }

  ngOnInit() {
    this.changePwdFormInit();
    this.changeEmailFormInit();
    this.getUserData();
  }

}

import { Component, OnInit } from "@angular/core";
import { validateEmail, validNumber } from "../../../../shared/form-validators";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { ApirequestService } from "../../../../services/apirequest/apirequest.service";
import { LoginService } from "../../../../services/login/login.service";
import { ErrorMessages } from "../../../../shared/error-messages";
import { Router } from "@angular/router";
import { messageBoxIcon, iconSet } from "../../../../shared/shared.data";
import { devEnv, captchaKey } from "../../../../config/batp.config";
import { TwoFactorService } from "src/app/services/twoFactor/two-factor.service";

@Component({
  selector: "batp-home-user-login",
  templateUrl: "./home-user-login.component.html",
  styleUrls: ["./home-user-login.component.css"]
})
export class HomeUserLoginComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _req: ApirequestService,
    private _route: Router,
    private _tFa: TwoFactorService,
    private _auth: LoginService
  ) {}

  userLoginForm: FormGroup;
  twoFactorLogin: FormGroup;
  userFormSubmitted: boolean = false;
  errorMessages: any = new ErrorMessages();
  showLoader: boolean = false;
  loginStatus: boolean = false;
  loginMessage: any = "";
  messIcons: iconSet = messageBoxIcon;
  captchaKey: string = captchaKey;
  userLoginFormInit() {
    this.userLoginForm = this._fb.group({
      email: ["", [Validators.required, validateEmail]],
      password: ["", [Validators.required]],
      g_recatcha: ["", devEnv ? [] : [Validators.required]]
    });
  }
  twoFactorFormInit() {
    this.twoFactorLogin = this._fb.group({
      two_factor_code: [
        "",
        [
          Validators.required,
          validNumber,
          Validators.minLength(6),
          Validators.maxLength(6)
        ]
      ]
    });
  }

  userLoginData: any;
  isTwoFactorEnabled: boolean = false;

  userLogin() {
    this.userFormSubmitted = true;
    if (this.userLoginForm.valid) {
      this._auth.loginStatus.next(null);
      this._tFa.twoFactorData.next(null);
      this.showLoader = true;
      let formVal: any = this.userLoginForm.value;
      this.userLoginData = {
        email: formVal.email,
        password: formVal.password
      };
      this._auth.login(this.userLoginData);
      this.getLoginStatus();
      this.getLoginMessages();
      this.loaderStatus();
    }
  }
  getSlStatus($evt) {
    this.showLoader = true;
    if (Object.keys($evt.error).length != 0) {
      this.loginStatus = false;
      this.showLoader = false;
      this.loginMessage = $evt.error["Error Description"];
    }
  }

  enableTwoFactor() {
    this.isTwoFactorEnabled = true;
    this.twoFactorFormInit();
  }

  loginStatSubs: any;
  loginResData: any = [];
  getLoginStatus() {
    this._auth.loginStatus.subscribe(data => {
      if (data) {
        if (data.hasOwnProperty("idUser")) {
          this.loginResData = data;
          this.enableTwoFactor();
        }
      }
    });
  }
  getLoginMessages() {
    this._auth.loginErrMsg.subscribe(data => {
      if (data != "") {
        this.loginMessage = data;
      }
    });
  }

  loaderStatus() {
    this._auth.showLoader.subscribe(data => {
      this.showLoader = data;
    });
  }
  handleSuccess(evt) {}

  twoFactorSubmit() {
    if (this.twoFactorLogin.valid) {
      this.showLoader = true;
      this._tFa.checkTfaCodeData.next(null);
      let formVal = this.twoFactorLogin.value;
      let data = {
        code: formVal.two_factor_code,
        secret: this.loginResData.user2faSecretKey
      };
      this._tFa.checkCodeAtLogin(data);
      this._tFa.checkTfaCodeData.subscribe(data => {
        if (data) {
          let res = data.data;
          if (data.status === true) {
            this.showLoader = false;
            if (res != "") this._auth.saveLoginData(this.loginResData);
          } else if (data.status === false) {
            this.showLoader = false;
            if (res != "") {
              this.loginMessage = res["Error Description"];
              this.loginStatus = false;
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    this.userLoginFormInit();
    this.twoFactorFormInit();
  }
}

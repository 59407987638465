import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { wizardMessages, wizardErrorMessage } from '../../../../shared/wizard-error-messages';
import { validPhoneNumber, validateEmail, validNumber, ValidAge } from '../../../../shared/form-validators';
import { FsUploadService } from '../../../../services/fs-upload/fs-upload.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'batp-wiz-management-information',
  templateUrl: './wiz-management-information.component.html',
  styleUrls: ['./wiz-management-information.component.css']
})
export class WizManagementInformationComponent implements OnInit {

  constructor(private _fb:FormBuilder,private _fs:FsUploadService) { }
  @Input() goTo:any = '';
  @Input() companyMgmtDetail:any = '';
  @Output() descStepper:any = new EventEmitter();
  @Output() companyMgmtDatas = new EventEmitter();

  errorMessage:wizardMessages = wizardErrorMessage;
  companyMgmtForm:FormGroup;
  companyMgmtFormInit() {
    this.companyMgmtForm = this._fb.group({
      ofcrfirstname: ['',[Validators.required]],
      ofcrlastname: ['',[Validators.required]],
      position: ['',[Validators.required]],
      dateOfBirth: ['',[Validators.required,ValidAge]],
      passportNumber: ['',[Validators.required]],
      telNumber: ['',[Validators.required]],
      areaCode:['',[Validators.required]],
      countryCode:['',[Validators.required]],
      ofcrmail: ['',[Validators.required,validateEmail]],
      memberImage: ['',[Validators.required]],
      ofcrWebsite: ['',[]],
      ofcrLinkedin:['',[]],
      ofcrphone:['',[]],
     // ofcLists : this._fb.array( [this.createOfcFields(true)] )
     ofcLists: this._fb.array([])
    });
  }

  get formData() { return <FormArray>this.companyMgmtForm.get('ofcLists'); }

  createOfcFields(req:boolean = false): FormGroup {
    return this._fb.group({
      ofcrfirstname: ['',[Validators.required]],
      ofcrlastname: ['',[Validators.required]],
      position: ['',[Validators.required]],
      dateOfBirth: ['',[Validators.required]],
      passportNumber: ['',[Validators.required]],
      telNumber: ['',[Validators.required]],
      areaCode:['',[Validators.required]],
      countryCode:['',[Validators.required]],
      ofcrmail: ['',[Validators.required,validateEmail]],
      memberImage: ['',[Validators.required]],
      ofcrWebsite: ['',[]],
      ofcrLinkedin:['',[]],
      ofcrphone:['',[]],
    });
  }
  getManagementData(data) {
    let mgmt = [{
        ofcrfirstname: data.ofcrfirstname,
        ofcrlastname: data.ofcrlastname,
        ofcrphone: data.ofcrphone,
        ofcrmail: data.ofcrmail,
        ofcrshares: data.ofcrshares,
        position: data.position,
        memberImage: data.memberImage,
        dateOfBirth:data.dateOfBirth,
        passportNumber: data.passportNumber,
        ofcrWebsite:data.ofcrWebsite,
        ofcrLinkedin:data.ofcrLinkedin,
      },...data.ofcLists];
    return mgmt;
  }
  companyMgmtFormSubmit() {
   if(this.companyMgmtForm.valid) {
      this.companyMgmtDetail = this.getManagementData(this.companyMgmtForm.value);
      let managementInfo = { managementInfo: this.companyMgmtDetail };
      let exportData:any = {
        formData: managementInfo,
        goTo: 4
      };
      this.companyMgmtDatas.emit(exportData);
   }
  }

  goToPrevious() {
    this.companyMgmtDetail = this.getManagementData(this.companyMgmtForm.value);
    let managementInfo = { managementInfo: this.companyMgmtDetail };
    let prevExpData:any = {
      formData: managementInfo,
      goTo: 2
    };
    this.descStepper.emit(prevExpData);
    // this.descStepper.emit(1);
  }

  addMgmtMembers(): void {
    let officers = this.companyMgmtForm.get('ofcLists') as FormArray;
    officers.push(this.createOfcFields());
  }

  removeMgmtMembers(i) {
    let officers = this.companyMgmtForm.get('ofcLists') as FormArray;
    officers.removeAt(i);
  }

  dateOfBirth:any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    console.log(event)
    const d = new Date(event.value);
    this.dateOfBirth = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.dateOfBirth = new Date(this.dateOfBirth);
  }
  setFormValues() {
    setTimeout(()=>{
      if(typeof this.companyMgmtForm != undefined) {
        //let officers = this.companyMgmtDetail.shift();
        let ofcrph = this.companyMgmtDetail.managementInfo[0].ofcrphone;
        let sp = ofcrph.split("-");
        this.companyMgmtForm.patchValue(
          {
            'ofcrfirstname': this.companyMgmtDetail.managementInfo[0].ofcrfirstname,
            'ofcrlastname': this.companyMgmtDetail.managementInfo[0].ofcrlastname,
            'telNumber': sp[2],
            'areaCode': sp[1],
            'countryCode': sp[0],
            'ofcrphone': this.companyMgmtDetail.managementInfo[0].ofcrphone,
            'ofcrmail': this.companyMgmtDetail.managementInfo[0].ofcrmail,
            'dateOfBirth': this.companyMgmtDetail.managementInfo[0].dateOfBirth,
            'passportNumber': this.companyMgmtDetail.managementInfo[0].passportNumber,
            'ofcrWebsite': this.companyMgmtDetail.managementInfo[0].ofcrWebsite,
            'ofcrLinkedin': this.companyMgmtDetail.managementInfo[0].ofcrLinkedin,
            'position': this.companyMgmtDetail.managementInfo[0].position,
            'memberImage': this.companyMgmtDetail.managementInfo[0].memberImage,
          //  'ofcLists': this.getOfcList(this.companyMgmtDetail.managementInfo)
          }
        );
        this.getOfcList(this.companyMgmtDetail.managementInfo);
      }
    },200);
  }
  getOfcList(officer) {
    let ofcFields = <FormArray>this.companyMgmtForm.get('ofcLists');
    ofcFields.removeAt(0);
    officer.forEach((ele,i) => {
      if(i != 0) {
        ofcFields.push(this._fb.group({
          ofcrfirstname: [ele.ofcrfirstname, ( i == 0 ) ? [Validators.required] : [] ],
          ofcrlastname: [ele.ofcrlastname, ( i == 0 ) ? [Validators.required] : []],
          position: [ele.position, ( i == 0 ) ? [Validators.required] : []],
          dateOfBirth: [ele.dateOfBirth, ( i == 0 ) ? [Validators.required] : []],
          passportNumber: [ele.passportNumber, ( i == 0 ) ? [Validators.required] : []],
         // ofcrphone: [ele.ofcrphone, ( i == 0 ) ? [Validators.required] : [validPhoneNumber] ],
          ofcrmail: [ele.ofcrmail, ( i == 0 ) ? [Validators.required] : [validateEmail] ],
          // ofcrshares: [ele.ofcrshares, ( i == 0 ) ? [Validators.required] : [validNumber,Validators.max(100)]],
         // ofcrshares: [ele.ofcrshares],
          ofcrWebsite: [ele.ofcrWebsite, []],
          ofcrLinkedin: [ele.ofcrLinkedin,[]],
          ofcrphone: [ele.ofcrphone,[]],
          memberImage: [ele.memberImage, ( i == 0 ) ? [Validators.required] : []],
          countryCode: [ele.countryCode, []],
          areaCode: [ele.areaCode,[]],
          telNumber: [ele.telNumber, []]
        }));
      }
    });
  }


  getOfcPhone() {
    const countryCode = this.companyMgmtForm.get('countryCode').value
    const areaCode = this.companyMgmtForm.get('areaCode').value
    const telNumber = this.companyMgmtForm.get('telNumber').value
    this.companyMgmtForm.get('ofcrphone').setValue(`${countryCode}-${areaCode}-${telNumber}`)
  }
  getOfcClonePhone(i) {
    const ofcLists = <FormArray> this.companyMgmtForm.get('ofcLists');
    const countryCode = ofcLists.controls[i].get('countryCode').value
    const areaCode = ofcLists.controls[i].get('areaCode').value
    const telNumber = ofcLists.controls[i].get('telNumber').value
    ofcLists.controls[i].get('ofcrphone').setValue(`${countryCode}-${areaCode}-${telNumber}`)
  }


  uplaodMgmtImg(ind = null) {
    let fileAccepted = ["image/*"];
    let maxSize = 2097152;
    this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
      if(res.filesUploaded.length > 0) this.fileUploadHandler(ind,res)
    });
  }
  fileUploadHandler(ind,res) {
    let filesFailed = res.filesFailed;
    let fileUploaded = res.filesUploaded;
    if(ind != null) {
      if(fileUploaded.length > 0 ) {
        let ofcFields = <FormArray>this.companyMgmtForm.get('ofcLists');
        ofcFields.controls[ind].get('memberImage').setValue(fileUploaded[0].url);
      }
    } else {
      if(fileUploaded.length > 0 ) {
        this.companyMgmtForm.get('memberImage').setValue(fileUploaded[0].url);
      }
    }
  }
  removeProfileImg(ind = null) {
    if(ind != null) {
      let ofcFields = <FormArray> this.companyMgmtForm.get('ofcLists');
      ofcFields.controls[ind].get('memberImage').setValue('');
    } else {
      this.companyMgmtForm.get('memberImage').setValue('');
    }
  }





  ngOnInit() {
    this.companyMgmtFormInit();
  }

  ngOnChanges() {
    if(this.companyMgmtDetail != '') {
      this.setFormValues();
    }
  }
}

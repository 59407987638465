import { Injectable } from '@angular/core';
import { ApirequestService } from '../apirequest/apirequest.service';
import { _ibin_api_url } from 'src/app/config/batp.config';

@Injectable({
  providedIn: 'root'
})
export class IbinService {

  constructor(
    private _req: ApirequestService
  ) { }

  getIbinData(ibin) {
    let url = `${_ibin_api_url}ibin/${ibin}`;
    return this._req.fetchApiData(url,{},false,'get');
  }

}
